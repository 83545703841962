import { callApi } from '../../utils/apiCaller';
import { Notification } from '../../components/atoms/notification';
import {
    GET_VENDOR_RATING,
    STRIPE_CONNECT,
    FUND_DETAIL,
    STRIPE_PAYMENTS,
    STRIPE_TRANSFERS,
    STRIPE_PAYOUTS,
    STRIPE_REFUNDS,
    STRIPE_PAYMENT_DETAIL,
    STRIPE_ACCOUNT_DETAIL,
    BUSINESS_INFORMATION,
    UPDATE_BUSINESS_INFORMATION,
    UPDATE_BUSINESS_STATE,
    UPDATE_OFFICE_INFORMATION,
    UPDATE_OFFICE_STATE,
    LICENSE_INFORMATION,
    UPDATE_LICENSE_INFORMATION,
    UPDATE_LICENSE_STATE,
    // INSURRANCE_INFORMATION,
    UPDATE_INSURRANCE_INFORMATION,
    UPDATE_INSURRANCE_STATE,
    UPDATE_PROFILE,
    GET_ALL_COUNTRIES,
    STRIPE_SINGLE_TRANS_DETAIL,
    BUSINESS_BANK_ACCOUNT_INFORMATION,
    ANALYSIS_DETAIL,
    COLLECTED_FEES,
    BANKS_PAYSTACK,
    PAYSTACK_ACCOUNT_DET,
    PAYSTACK_PAYMENTS,
    PAYSTACK_PAYOUTS,
    PAYSTACK_REFUNDS,
    FETCH_LOCATION
} from 'store/types';
import { loading_, elseNotifyError } from 'store/actions';
import { queryBuilder } from 'utils';

export const getVendorRating_ = (payload) => ({ type: GET_VENDOR_RATING, payload });
export const getVendorRating = () => async (dispatch) => {
    let response = await callApi('/me/vendor-rating', 'GET');
    const status = response?.status;

    if (status === 200) {
        return dispatch(getVendorRating_(response.data));
    } else {
        if (
            response?.data &&
            Array.isArray(response?.data?.errors) &&
            response?.data?.errors.length
        ) {
            return Notification('error', response?.data?.errors[0]?.message, status);
        } else {
            return Notification('error', response?.data?.message, status);
        }
    }
};

export const getAllCountries_ = (payload) => ({ type: GET_ALL_COUNTRIES, payload });
export const getAllCountries = () => {
    return (dispatch) => {
        return callApi('/countries', 'GET').then((response) => {
            const status = response?.status;

            if (status === 200) {
                return dispatch(getAllCountries_(response.data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response?.data?.message, status);
                }
            }
        });
    };
};

export const verifyCountryCode = (code) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/country-code?code=${code}`, 'GET')
            .then((response) => {
                const status = response?.status;

                if (status === 200) {
                    return { ...response.data?.data, message: response.data.message };
                } else {
                    if (
                        response?.data &&
                        Array.isArray(response?.data?.errors) &&
                        response?.data?.errors.length
                    ) {
                        return Notification('error', response.data.errors[0].message, status);
                    } else {
                        return Notification('error', response?.data?.message, status);
                    }
                }
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const getBusinessInformation_ = (payload) => ({ type: BUSINESS_INFORMATION, payload });
export const getBusinessInformation = () => {
    return (dispatch) => {
        return callApi('/business-information', 'GET').then((response) => {
            const status = response?.status;

            if (status === 200) {
                return dispatch(getBusinessInformation_(response.data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response?.data?.message, status);
                }
            }
        });
    };
};

export const updateBusinessInformation_ = (payload) => ({
    type: UPDATE_BUSINESS_INFORMATION,
    payload
});
export const updateCompanyInformation = (data) => {
    return (dispatch) => {
        return callApi('/update-company-information', 'POST', data).then((response) => {
            const status = response.status;
            const successMsg = response?.data?.message || response?.message;

            if (status === 200) {
                Notification('success', successMsg, status);
                return dispatch(updateBusinessInformation_(data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response?.data?.message, status);
                }
            }
        });
    };
};

export const updateOfficeInformation_ = (payload) => ({ type: UPDATE_OFFICE_INFORMATION, payload });
export const updateOfficeInformation = (data) => {
    return (dispatch) => {
        return callApi('/update-office-information', 'POST', data).then((response) => {
            const status = response.status;
            const successMsg = response?.data?.message || response?.message;

            if (status === 200) {
                dispatch(updateOfficeInformation_(data));
                Notification('success', successMsg, status);
                return true;
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response?.data?.message, status);
                }
            }
        });
    };
};

export const getLicenseInformation_ = (payload) => ({ type: LICENSE_INFORMATION, payload });
export const getLicenseInformation = () => {
    ///api/v1/me
    return (dispatch) => {
        callApi('/license-information', 'GET').then((response) => {
            const status = response.status;

            if (status === 200) {
                dispatch(getLicenseInformation_(response.data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response?.data?.message, status);
                }
            }
        });
    };
};

export const updateLicenseInformation_ = (payload) => ({
    type: UPDATE_LICENSE_INFORMATION,
    payload
});
export const updateLicenseInformation = (data) => {
    return (dispatch) => {
        callApi('/update-license-information', 'POST', data).then((response) => {
            const status = response.status;
            const successMsg = response?.data?.message || response?.message;

            if (status === 200) {
                dispatch(updateLicenseInformation_(data));
                return Notification('success', successMsg, status);
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response?.data?.message, status);
                }
            }
        });
    };
};

export const updateInsurranceInformation_ = (payload) => ({
    type: UPDATE_INSURRANCE_INFORMATION,
    payload
});
export const updateInsurranceInformation = (data) => {
    return (dispatch) => {
        callApi('/update-insurrance-policy', 'POST', data).then((response) => {
            const status = response.status;
            const successMsg = response?.data?.message || response?.message;

            if (status === 200) {
                dispatch(updateInsurranceInformation_(data));
                return Notification('success', successMsg, status);
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response?.data?.message, status);
                }
            }
        });
    };
};

export const updateProfile_ = (payload) => ({ type: UPDATE_PROFILE, payload });
export const updateProfile = (data) => {
    return (dispatch) => {
        callApi('/users/update-profile', 'POST', data).then((response) => {
            const status = response.status;
            const successMsg =
                response?.data?.message || response?.message || 'Profile update Successfully';

            if (status === 200) {
                dispatch(updateProfile_(data));
                return Notification('success', successMsg, status);
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response?.data?.message, status);
                }
            }
        });
    };
};

export const updateBusinessState = (payload) => ({ type: UPDATE_BUSINESS_STATE, payload });
export const updateOfficeState = (payload) => ({ type: UPDATE_OFFICE_STATE, payload });
export const updateLicenseState = (payload) => ({ type: UPDATE_LICENSE_STATE, payload });
export const updateInsuranceState = (payload) => ({ type: UPDATE_INSURRANCE_STATE, payload });

export const connectStripeAccount_ = (payload) => ({ type: STRIPE_CONNECT, payload });
export const connectStripeAccount = (data) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi('/create-account', 'POST', data)
            .then((response) => {
                const status = response.status;

                if (status === 200) {
                    return dispatch(connectStripeAccount_(response.data));
                } else {
                    if (
                        response?.data &&
                        Array.isArray(response?.data?.errors) &&
                        response?.data?.errors.length
                    ) {
                        return Notification('error', response.data.errors[0].message, status);
                    } else {
                        return Notification('error', response?.data?.message, status);
                    }
                }
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const getFundDetail_ = (payload) => ({ type: FUND_DETAIL, payload });
export const getFundDetail = () => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi('/get-total-payout', 'get')
            .then((response) => {
                const status = response.status;

                if (status === 200) {
                    return dispatch(getFundDetail_(response.data?.data));
                } else {
                    if (
                        response?.data &&
                        Array.isArray(response?.data?.errors) &&
                        response?.data?.errors.length
                    ) {
                        return Notification('error', response.data.errors[0].message, status);
                    } else {
                        return Notification('error', response?.data?.message, status);
                    }
                }
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const getStripePayments_ = (payload) => ({ type: STRIPE_PAYMENTS, payload });
export function getStripePayments(query) {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/finances${queryBuilder(query)}`, 'get')
            .then((response) => {
                const status = response.status;

                if (status === 200) {
                    return dispatch(getStripePayments_(response.data?.data));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
}

export const getStripeTransfer_ = (payload) => ({ type: STRIPE_TRANSFERS, payload });
export function getStripeTransfer({ lastId, perPage = 10 }) {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(
            `/retrieve-transfer?perPage=${perPage}${lastId ? '&lastId=' + lastId : ''}`,
            'get'
        )
            .then((response) => {
                const status = response.status;
                // response.data.charges = data;

                if (status === 200) {
                    return dispatch(
                        getStripeTransfer_({
                            data: response.data?.transfers?.data,
                            hasMore: response.data?.transfers?.has_more
                        })
                    );
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
}

export const getStripePayouts_ = (payload) => ({ type: STRIPE_PAYOUTS, payload });
export function getStripePayouts({ lastId, perPage = 10 }) {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(
            `/retrieve-payout?perPage=${perPage}${lastId ? '&lastId=' + lastId : ''}`,
            'get'
        )
            .then((response) => {
                const status = response.status;
                // response.data.charges = data;

                if (status === 200) {
                    return dispatch(
                        getStripePayouts_({
                            data: response.data?.payouts?.data,
                            hasMore: response.data?.payouts?.has_more
                        })
                    );
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
}

export const getStripeRefunds_ = (payload) => ({ type: STRIPE_REFUNDS, payload });
export function getStripeRefunds(query) {
    return (dispatch) => {
        dispatch(loading_(true));
        query.financeStatus = 'completed';
        return callApi(`/finances/refunds${queryBuilder(query)}`, 'get')
            .then((response) => {
                const status = response.status;

                if (status === 200) {
                    return dispatch(getStripeRefunds_(response.data?.data));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
}

export const getStripeSingleTransaction_ = (payload) => ({
    type: STRIPE_SINGLE_TRANS_DETAIL,
    payload
});
export function getStripeSingleTransaction({ id, url }) {
    return (dispatch) => {
        dispatch(loading_(true));
        let txt = ``;
        if (url === 'refunds') txt = `/finances/refunds/${id}`;
        else txt = `/retrieve-${url}/${id}`;
        return callApi(txt, 'get')
            .then((response) => {
                const status = response.status;
                console.log('lokesh response', response);
                if (status === 200) {
                    let res = {};
                    if (url === 'collected-fee') res = response.data['collectedFee'];
                    if (url === 'refunds') res = response.data['data']['0'];
                    console.log('lokesh res', res);
                    return dispatch(getStripeSingleTransaction_(res));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
}

export const getStripePaymentDetail_ = (payload) => ({ type: STRIPE_PAYMENT_DETAIL, payload });
export function getStripePaymentDetail(id, info) {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/finances/${id}`, 'get')
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    let data = Array.isArray(response.data?.data)
                        ? response.data?.data[0]
                        : response.data?.data;
                    if (info) data = { ...info, ...data };
                    return dispatch(getStripePaymentDetail_(data));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
}

export function getStripeDashboardLink() {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/dashboard-login`, 'get')
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    return response.data?.data || response.data?.linkedAccountdata;
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
}

export const getVendorStripeAccountDetails_ = (payload) => ({
    type: STRIPE_ACCOUNT_DETAIL,
    payload
});
export function getVendorStripeAccountDetails() {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/retrieve-account`, 'get')
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    // response.data.data = accountDetail;
                    return dispatch(getVendorStripeAccountDetails_(response.data?.customerAccount));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
}

export const getBusinessBankAccountInfo_ = (payload) => ({
    type: BUSINESS_BANK_ACCOUNT_INFORMATION,
    payload
});
export const getBusinessBankAccountInfo = () => {
    ///api/v1/me
    return (dispatch) => {
        callApi('/bank-information', 'GET').then((response) => {
            const status = response?.status;

            if (status === 200) {
                return dispatch(getBusinessBankAccountInfo_(response.data?.data));
            } else elseNotifyError(response, status);
        });
    };
};

export const updateBankAccountInformation_ = (payload) => ({
    type: BUSINESS_BANK_ACCOUNT_INFORMATION,
    payload
});
export const updateBankAccountInformation = (data) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi('/bank-information', 'PUT', data)
            .then((response) => {
                const status = response.status;
                const successMsg = response?.data?.message || response?.message;

                if (status === 200) {
                    Notification('success', successMsg, status);
                    return dispatch(updateBankAccountInformation_(data));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const createBankAccountInformation_ = (payload) => ({
    type: BUSINESS_BANK_ACCOUNT_INFORMATION,
    payload
});
export const createBankAccountInformation = (data) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi('/bank-information', 'POST', data)
            .then((response) => {
                const status = response.status;
                const successMsg = response?.data?.message || response?.message;

                if (status === 200) {
                    Notification('success', successMsg, status);
                    return dispatch(createBankAccountInformation_(data));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const isFilledRequiedInfoForStripeOnboard = () => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi('/is-vendor-account-veried', 'GET')
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    return response?.data?.data;
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const getVendorFundAnalysis_ = (payload) => ({ type: ANALYSIS_DETAIL, payload });
export const getVendorFundAnalysis = () => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi('/finances/analysis', 'get')
            .then((response) => {
                const status = response.status;

                if (status === 200) {
                    return dispatch(getVendorFundAnalysis_(response.data?.data));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const createPaystackAccount = (data) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi('/paystack/create-account', 'POST', data)
            .then((response) => {
                const status = response.status;
                const successMsg = response?.data?.message || response?.message;

                if (status === 200) {
                    Notification('success', successMsg, status);
                    return response.data;
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const updatePaystackAccount = (data) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi('/paystack/update-account', 'POST', data)
            .then((response) => {
                const status = response.status;
                const successMsg = response?.data?.message || response?.message;

                if (status === 200) {
                    Notification('success', successMsg, status);
                    return response.data;
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const getBanksPaystack_ = (payload) => ({ type: BANKS_PAYSTACK, payload });
export const getBanksPaystack = () => {
    return (dispatch) => {
        return callApi('/paystack/banks', 'GET').then((response) => {
            const status = response?.status;

            if (status === 200) {
                return dispatch(getBanksPaystack_(response.data?.data?.data));
            } else elseNotifyError(response, status);
        });
    };
};

export const verifyPaystack_ = (payload) => ({ type: BANKS_PAYSTACK, payload });
export const verifyPaystack = (query) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/paystack/resolve${queryBuilder(query)}`, 'GET')
            .then((response) => {
                const status = response?.status;

                if (status === 200 || status === 422 || status === 400) {
                    return response.data?.data;
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const getPaystackTotalBal_ = (payload) => ({ type: PAYSTACK_ACCOUNT_DET, payload });
export const getPaystackTotalBal = () => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/paystack/transactions/total`, 'GET')
            .then((response) => {
                const status = response?.status;
                if (status === 200) {
                    return dispatch(getPaystackTotalBal_(response?.data?.data));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const getPaystackOtherBal = () => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/paystack/settlements-total`, 'GET')
            .then((response) => {
                const status = response?.status;
                if (status === 200) {
                    return dispatch(getPaystackTotalBal_(response?.data?.data));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const getPaystackPayments_ = (payload) => ({ type: PAYSTACK_PAYMENTS, payload });
export function getPaystackPayments(query) {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/paystack/transactions${queryBuilder(query)}`, 'get')
            .then((response) => {
                const status = response.status;

                if (status === 200) {
                    return dispatch(getPaystackPayments_(response.data?.data));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
}

export const getPaystackPayouts_ = (payload) => ({ type: PAYSTACK_PAYOUTS, payload });
export function getPaystackPayouts(query) {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/paystack/settlements${queryBuilder(query)}`, 'get')
            .then((response) => {
                const status = response.status;

                if (status === 200) {
                    return dispatch(getPaystackPayouts_(response.data?.data));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
}

export const getPaystackRefunds_ = (payload) => ({ type: PAYSTACK_REFUNDS, payload });
export function getPaystackRefunds(query) {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/paystack/refunds${queryBuilder(query)}`, 'get')
            .then((response) => {
                const status = response.status;

                if (status === 200) {
                    return dispatch(getPaystackRefunds_(response.data?.data));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
}

export function getPaystackSingleTransaction({ id, url }) {
    return (dispatch) => {
        dispatch(loading_(true));
        let subUrl = 'transaction/verify/';
        if (url === 'payout') subUrl = 'settlements-transactions/';
        else if (url === 'collected-fee') subUrl = 'retrieve-collected-fee/';
        return callApi(`/paystack/${subUrl}${id}`, 'get')
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    if (url === 'collected-fee') {
                        return dispatch(
                            getStripeSingleTransaction_(response?.data?.collectedFee ?? {})
                        );
                    }
                    const data = {
                        transaction: response?.data?.data.transaction ?? {},
                        paystackData: response?.data?.data?.paystackData?.data ?? {}
                    };
                    return dispatch(getStripeSingleTransaction_(data));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
}

export const fetchLocations_ = (payload) => ({ type: FETCH_LOCATION, payload });
export const fetchLocations = (locString) => {
    return (dispatch) => {
        return callApi(`/locations/query?q=${locString}`, 'GET').then((response) => {
            const status = response?.status;

            if (status === 200) {
                return dispatch(fetchLocations_(response.data?.data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response?.data?.message, status);
                }
            }
        });
    };
};
