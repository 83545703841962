/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import '../../add-an-itinerary/index.scss';
import { Button, Modal, ModalHeader } from 'reactstrap';
import { ImportItineraries } from 'components/atoms';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Proptypes from 'prop-types';
import {
    updateProductsPersonalInfo,
    updateProductStepTwoInfo,
    deleteAddOnProduct,
    fetchProductDetail,
    addMoreProducts_,
    getAllCalendarProducts,
    selectedExistingProduct,
    resetProductReducer,
    getPackageById,
    getCalendarsById
} from 'store/actions';

import { ConfirmationModal } from 'components/molecules';
import ItineraryFormPageTwo from './itinerary-form-page-two';
import ItineraryFormPageOne from './itinerary-form-page-one';
class AddAnItineraryModal extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            isConfirmationModalOpen: false
        };
    }
    componentDidUpdate() {}

    componentDidMount() {}

    onClose = () => {
        const {
            currentProduct,
            // selected_existing_product,
            setAddProductInitModalData,
            deleteAddOnProduct,
            resetProductReducer
        } = this.props;

        if (currentProduct && currentProduct?.id && !currentProduct?.title) {
            deleteAddOnProduct(currentProduct);
        }
        if (currentProduct) {
            // resetCalenderReducer({
            //     //create_addon_product: true,
            //     // update_customer_book: true,
            //     //update_calendar_product: true
            // });
            resetProductReducer({
                currentProduct: true
            });
        }
        setAddProductInitModalData(null);
        this.onClear();
        this.props.onClose();
    };

    onClear = () => {
        // this.formRef.current.resetFields();
        this.setState({
            page: 1,
            isConfirmationModalOpen: false
        });
    };

    onFinish = async ({ state }) => {
        const {
            title,
            describe,
            start,
            finish,
            media,
            admission_with_price,
            departure_time,
            departure_pickup_duration,
            anotherStart,
            maxDailyAvailability,
            isRecommended,
            isCheckBox,
            //startTimeAmPm,
            //endTimeAmPm,
            destination
        } = state;
        const {
            currentProduct,
            updateProductsPersonalInfo,
            selected_existing_product,
            updateProductStepTwoInfo,
            // setOpenActivityModal,
            get_calendar_data,
            package_detail,
            getAllCalendarProducts,
            selectedExistingProduct,
            getPackageById,
            getCalendarsById
        } = this.props;

        if (this.state.page === 1) {
            const tmpMedia = [];
            media.map((res) => {
                tmpMedia.push({ type: res.type, url: res.url });
            });
            const createItinerary = {
                title: title,
                description: describe,
                address: destination?.name,
                cityId: destination?.city_id,
                // country: address.country,
                photos_and_videos: tmpMedia,
                spaces_available: maxDailyAvailability,
                calendar_id: get_calendar_data?.data?.id
                // lat: address?.latlng?.lat,
                // lng: address?.latlng?.lng
            };
            if (currentProduct && currentProduct.day_type === 'all_day') {
                createItinerary.start_time = start;
                createItinerary.finish_time = finish;
            }

            updateProductsPersonalInfo(createItinerary, currentProduct?.id).then(() => {
                //for displaying products on calendar detail
                getAllCalendarProducts(get_calendar_data.data.id);
                //for the logic of main product and package icons

                getCalendarsById(get_calendar_data.data.id);
                this.setState({ page: 2 }, () => {
                    if (package_detail?.id)
                        getPackageById(package_detail.id, get_calendar_data?.data?.id);
                    if (selected_existing_product) {
                        selectedExistingProduct({ id: currentProduct?.id });
                    }
                });
            });
        } else if (this.state.page === 2) {
            const data = {};

            if (admission_with_price)
                data.is_admission_included =
                    admission_with_price === 1 ? 'Yes' : admission_with_price === 2 ? 'No' : 'NIL';
            if (isRecommended || isRecommended === false)
                data.book_activity_without_accomodation = isRecommended;

            if (departure_time) data.departure_time = departure_time;
            if (departure_pickup_duration && Object.keys(departure_pickup_duration).length > 0)
                data.departure_pickup_duration = String(
                    departure_pickup_duration?.hour * 60 * 60 +
                        departure_pickup_duration?.minute * 60
                );

            data.meetup_info = {
                at_accommodation: false,
                at_starting_point: false,
                allow_users_to_enter_pickup_location: false,
                points: []
            };
            if (isCheckBox && Array.isArray(isCheckBox) && isCheckBox.length > 0) {
                if (isCheckBox.includes('1')) data.meetup_info.at_accommodation = true;
                if (isCheckBox.includes('2'))
                    data.meetup_info.allow_users_to_enter_pickup_location = true;
                if (isCheckBox.includes('3')) data.meetup_info.at_starting_point = true;
            }
            if (Array.isArray(anotherStart) && anotherStart.length > 0 && anotherStart[0] !== '')
                data.meetup_info.points = anotherStart;

            updateProductStepTwoInfo(data, currentProduct?.id).then((res) => {
                if (res?.payload?.data) {
                    //for displaying products on calendar detail
                    getAllCalendarProducts(get_calendar_data.data.id);
                    //for the logic of main product and package icons
                    getCalendarsById(get_calendar_data.data.id);
                    if (package_detail?.id)
                        getPackageById(package_detail.id, get_calendar_data?.data?.id);
                    // if (currentProduct && currentProduct.productType === 'main_product')
                    //     setOpenActivityModal(true);
                    this.onClose();
                }
            });
        }
    };

    render() {
        const { page } = this.state;
        const { currentProduct, hideImport, package_detail, get_calendar_data } = this.props;

        return (
            <>
                <Modal isOpen={this.props.isOpen} scrollable centered size="lg">
                    <ModalHeader>
                        <div className="d-flex align-items-center flex-wrap header">
                            <div className="order-2 order-md-1 mr-auto mr-2">
                                Add a {currentProduct?.productSubCategory?.name ?? 'Itinerary'}
                            </div>
                            <div className="order-1 order-md-2 col-12 col-md-auto px-0">
                                {page === 2 ? (
                                    <Button onClick={() => this.setState({ page: 1 })}>Back</Button>
                                ) : null}
                            </div>
                            <div className="pages unselectable order-3 ml-2">
                                <span className="text-nowrap">{`Page ${page} of 2`}</span>
                            </div>
                        </div>
                        <div className="header-import">
                            {hideImport ? (
                                ''
                            ) : (
                                <ImportItineraries
                                    calendars={this.props.itineraries}
                                    import="I will import Itinerary information from my other activity "
                                    onMainProduct={(item) => this.onSetMainProduct(item)}
                                />
                            )}
                        </div>
                    </ModalHeader>
                    {page === 1 && (
                        <ItineraryFormPageOne
                            state={this.state}
                            setState={(objOrFunc) => this.setState(objOrFunc)}
                            onFinish={this.onFinish}
                        />
                    )}

                    {page === 2 && (
                        <ItineraryFormPageTwo
                            state={this.state}
                            setState={(objOrFunc) => this.setState(objOrFunc)}
                            onFinish={this.onFinish}
                        />
                    )}
                </Modal>
                <ConfirmationModal
                    isModalOpen={this.state.isConfirmationModalOpen}
                    setModalOpenState={(value) =>
                        this.setState({
                            isConfirmationModalOpen: !!value
                        })
                    }
                    description="you want to cancel"
                    handleConfirmation={() => {
                        this.onClose();
                        //for the logic of main product and package icons
                        getCalendarsById(get_calendar_data.data.id);
                        if (package_detail?.id)
                            getPackageById(package_detail.id, get_calendar_data?.data?.id);
                    }}
                />
            </>
        );
    }
}
AddAnItineraryModal.propTypes = {
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    itineraries: Proptypes.any,
    calendarDay: Proptypes.number,
    calendar_data: Proptypes.object,
    setAddProductInitModalData: Proptypes.func,
    resetProductReducer: Proptypes.func,
    updateProductsPersonalInfo: Proptypes.func,
    currentProduct: Proptypes.object,
    hideImport: Proptypes.bool,
    selected_existing_product: Proptypes.bool,
    updateProductStepTwoInfo: Proptypes.func,
    // setOpenActivityModal: Proptypes.func,
    deleteAddOnProduct: Proptypes.func,
    fetchProductDetail: Proptypes.func,
    product_detail: Proptypes.object,
    add_more_products: Proptypes.array,
    addMoreProducts_: Proptypes.func,
    get_calendar_data: Proptypes.object,
    package_detail: Proptypes.object,
    match: Proptypes.object,
    getAllCalendarProducts: Proptypes.func,
    selectedExistingProduct: Proptypes.func,
    getPackageById: Proptypes.func,
    getCalendarsById: Proptypes.func
};

const mapStateToProps = (state) => ({
    itineraries: state.contentDetail.itineraries,
    selected_existing_product: state.product.selected_existing_product,
    product_detail: state.product.product_detail,
    add_more_products: state.product.add_more_products,
    get_calendar_data: state.calendar.get_calendar_data,
    currentProduct: state.product.currentProduct,
    package_detail: state.product.package_detail
});

const mapDispatch = (dispatch) => ({
    resetProductReducer: (data) => dispatch(resetProductReducer(data)),
    updateProductsPersonalInfo: (data, id) => dispatch(updateProductsPersonalInfo(data, id)),
    updateProductStepTwoInfo: (data, id) => dispatch(updateProductStepTwoInfo(data, id)),
    deleteAddOnProduct: (data) => dispatch(deleteAddOnProduct(data)),
    fetchProductDetail: (data) => dispatch(fetchProductDetail(data)),
    addMoreProducts_: (data) => dispatch(addMoreProducts_(data)),
    getAllCalendarProducts: (id) => dispatch(getAllCalendarProducts(id)),
    selectedExistingProduct: (data) => dispatch(selectedExistingProduct(data)),
    getPackageById: (id, cId) => dispatch(getPackageById(id, cId)),
    getCalendarsById: (id) => dispatch(getCalendarsById(id))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(AddAnItineraryModal));
