import { notification } from 'antd';

export const Notification = (type, messageDesc, status) => {
    if (!status) status = type;
    notification[type]({
        message: status === 200 || status === 'success' ? 'Success' : 'Error',
        description: messageDesc,
        top: 110,
        duration: 5
    });
};
