/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, Select } from 'antd';
import { Button, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { Feedback, ConfirmationModal } from 'components/molecules';
import {
    updateProductsCalendarDay,
    resetCalenderReducer,
    deleteAddOnProduct,
    // selectedExistingProduct,
    getCalendarsById,
    resetProductReducer,
    attatchExistingProductAsAddon,
    duplicateExistingProductAsAddon,
    getAllCalendarProducts
} from 'store/actions';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { compareCalendarDays } from 'utils';

const CalendarAllDaysModal = (props) => {
    const {
        selected_existing_product,
        // selectedExistingProduct,
        setEditMainProductInitModal,
        current_product,
        package_detail,
        calendar_products_list,
        get_calendar_data,
        attatchExistingProductAsAddon,
        duplicateExistingProductAsAddon,
        current_product_flow,
        getAllCalendarProducts
    } = props;
    const [calendarDaysForm] = Form.useForm();
    const params = useParams();

    const [calendarDaysForBooking, defaultCalendarDays] = getCalendarDaysForBooking();
    const [isConfirmationModalOpen, setConfirmationModalOpenState] = React.useState(false);
    const [calendarDaysIndeterminate, setCalendarDaysIndeterminate] = React.useState(true);
    const [checkAllCalendarDaysForBooking, setCheckAllCalendarDaysForBooking] =
        React.useState(false);
    const [calendarDays, setCalendarDays] = React.useState([]);
    const [isCanceling, setIsCanceling] = React.useState(false);

    useEffect(() => {
        if (selected_existing_product && Array.isArray(current_product?.calendar_days?.days)) {
            onCalendarDaysForBookingChange(current_product.calendar_days.days);
            calendarDaysForm.setFieldsValue({
                days: current_product.calendar_days.days
            });

            //Find suggested day of product from all product list of the calendar
            if (
                current_product?.day_type === 'within_day' &&
                Array.isArray(calendar_products_list) &&
                calendar_products_list.length > 0
            ) {
                let found = calendar_products_list.find((it) => it.id == current_product?.id);
                if (found?.suggestedDay) {
                    calendarDaysForm.setFieldsValue({
                        suggest_specific_day: found.suggestedDay.toString()
                    });
                }
            }
        }
    }, [selected_existing_product, current_product?.updated_at, calendar_products_list]);

    function getCalendarDaysForBooking() {
        const { get_calendar_data } = props;
        const uniqueWeekDays = [];
        const calendarDataDays = get_calendar_data?.data?.days;
        calendarDataDays.forEach((item) => {
            if (!uniqueWeekDays.includes(item.day_of_week)) {
                uniqueWeekDays.push(item.day_of_week);
            }
        });
        const bookingDays = uniqueWeekDays.map((item, index) => ({
            label: item,
            value: index
        }));
        return [bookingDays, uniqueWeekDays];
    }

    const onCheckAllCalendarDaysForBookingChange = (e) => {
        setCalendarDays(e.target.checked ? defaultCalendarDays : []);
        setCalendarDaysIndeterminate(false);
        setCheckAllCalendarDaysForBooking(e.target.checked);
        //const days = e.target.days;

        calendarDaysForm.setFieldsValue({
            days: e.target.checked ? defaultCalendarDays : []
        });
    };
    const onCalendarDaysForBookingChange = (list) => {
        setCalendarDays(list);
        setCalendarDaysIndeterminate(!!list.length && list.length < calendarDaysForBooking.length);
        setCheckAllCalendarDaysForBooking(list.length === calendarDaysForBooking.length);
    };
    function handleCancelCalendarDaysBooking() {
        const { current_product, deleteAddOnProduct, getCalendarsById, get_calendar_data } = props;
        if (current_product && current_product?.id && !current_product?.title) {
            deleteAddOnProduct(current_product);
        }
        setCalendarDays([]);
        generalReset({
            resetForm: true,
            toggleCurrentModal: true,
            resetPreviousModal: true
        });
        props.closeCalendarModal();
        setConfirmationModalOpenState(false);
        getCalendarsById(get_calendar_data.data.id);
        getAllCalendarProducts(get_calendar_data.data.id);
    }
    const { Option } = Select;

    function onFinish() {
        //existing flow
        if (selected_existing_product) {
            const days = calendarDaysForm.getFieldsValue().days;
            const suggestedDay = calendarDaysForm.getFieldsValue().suggest_specific_day;
            let found;
            if (Array.isArray(calendar_products_list))
                found = calendar_products_list.find((it) => it.id == current_product?.id);

            if (
                compareCalendarDays(
                    current_product?.calendar_days?.days,
                    days,
                    found?.suggestedDay,
                    suggestedDay,
                    current_product?.day_type
                )
            )
                return setConfirmationModalOpenState(true);
            else if (Object.keys(package_detail).length > 0) {
                //update product silently so to the package to for attaching purpose
                return updateCalendarDays(null, true);
            } else if (
                current_product?.day_type === 'all_day' &&
                (current_product_flow?.title === 'attach-product' ||
                    current_product_flow?.title === 'duplicate-product')
            ) {
                //assign or duplicate product silently
                return duplicateOrAssignProduct(true);
            } else return props.updateAndContinue();
            //other wise close and continue
        } else updateCalendarDays();
    }

    const onConfirmYes = () => {
        //if a main product or a package flow
        if (current_product?.productType || Object.keys(package_detail).length > 0) {
            updateCalendarDays();
        } else if (
            current_product?.day_type === 'all_day' &&
            (current_product_flow?.title === 'attach-product' ||
                current_product_flow?.title === 'duplicate-product')
        ) {
            duplicateOrAssignProduct();
        } else {
            updateCalendarDays();
        }
    };

    function duplicateOrAssignProduct(silently) {
        let apiType = checkAttachOrDuplicate();
        if (apiType === 'attach') {
            attatchExistingProductAsAddon({
                calendar_id: get_calendar_data?.data?.id,
                product_id: current_product?.id
            }).then((prod) => {
                if (prod?.id) updateCalendarDays(prod.id, silently);
            });
        } else {
            duplicateExistingProductAsAddon({
                calendar_id: get_calendar_data?.data?.id,
                product_id: current_product?.id
            }).then((prod) => {
                if (prod?.id) updateCalendarDays(prod?.id, silently);
            });
        }

        props.resetProductReducer({ current_product_flow: true });
    }

    const updateCalendarDays = (id, silently) => {
        //generating payload data
        let data = {
            calendar_days: {
                days: calendarDaysForm.getFieldsValue().days
            }
        };
        if (props.current_product?.day_type === 'within_day') {
            data.suggested_day = Number(calendarDaysForm.getFieldsValue().suggest_specific_day);
            if (package_detail?.id) data.package_id = Number(package_detail.id);

            data.calendar_id = Number(params.id);
        }
        if (!id) id = current_product?.id;

        props.updateProductsCalendarDay(data, id, silently).then(() => {
            props.updateAndContinue();
        });
    };

    const checkAttachOrDuplicate = () => {
        let filteredArray = [];
        let type = '';
        if (calendar_products_list && calendar_products_list.length > 0) {
            filteredArray = calendar_products_list.filter((it) => it.id == current_product?.id);
        }
        if (filteredArray.length === 0) {
            type = 'attach';
        } else {
            type = 'duplicate';
        }
        return type;
    };
    const generalReset = ({
        resetForm,
        toggleCurrentModal,
        resetPreviousModal,
        openPreviousModal
    }) => {
        if (resetForm) {
            calendarDaysForm.resetFields();
        }
        if (toggleCurrentModal) {
            props.closeCalendarModal();
        }
        if (resetPreviousModal) {
            // props.resetCalenderReducer({
            //     //create_addon_product: true,
            //     // update_customer_book: true,
            //     //update_calendar_product: true
            // });
            props.resetProductReducer({
                currentProduct: true,
                current_product_flow: true
            });
        }
        if (openPreviousModal) {
            if (props.current_product?.day_type === 'within_day') {
                props.setAddOnProductItineraryTimeModal(true);
            } else {
                props.setAddProductsProductTypeModal(true);
            }
        }
    };
    const getDropDownData = () => {
        const { get_calendar_data } = props;
        const calendarDataDays =
            calendarDays && calendarDays.length == 0
                ? []
                : get_calendar_data?.data?.days.map((item) => ({
                      day: item.day,
                      day_of_week: item.day_of_week
                  }));
        let dropdownData = (
            <>
                {calendarDataDays
                    .filter((item) => {
                        if (calendarDays.length === 0) {
                            return true;
                        } else {
                            if (calendarDays.includes(item.day_of_week)) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    })
                    .map((item, i) => {
                        return (
                            <Option value={`${item.day}`} key={i + 'op'}>
                                Day {item.day} - {item.day_of_week}
                            </Option>
                        );
                    })}
            </>
        );
        return dropdownData;
    };

    const handleGoBack = () => {
        let openPrevious = true;
        if (
            selected_existing_product &&
            current_product?.productType &&
            current_product?.productType == 'main_product'
        ) {
            openPrevious = false;
        }
        generalReset({
            resetForm: true,
            toggleCurrentModal: true,
            openPreviousModal: openPrevious
        });
        if (!openPrevious) {
            setEditMainProductInitModal(true);
        }
    };

    return (
        <>
            <Modal size="lg" centered scrollable isOpen={props.isOpen}>
                <Form
                    onFinish={onFinish}
                    form={calendarDaysForm}
                    className="d-flex flex-column h-100 form-label-fw-500"
                    layout="vertical"
                    requiredMark={false}>
                    <div className="modal-header flex-wrap flex-lg-nowrap">
                        <h5 className="modal-title order-2 order-lg-1">
                            Select the customers calendar days this booking apply?
                        </h5>
                        <div
                            className="flex-shrink-0 order-1 order-lg-2 ml-lg-2"
                            hidden={
                                (package_detail && package_detail?.id) ||
                                current_product_flow?.title === 'product-summary'
                            }>
                            <Button
                                color="link"
                                className="px-0 text-decoration-underline"
                                type="button"
                                onClick={() => {
                                    handleGoBack();
                                }}>
                                Go Back
                            </Button>
                        </div>
                    </div>
                    <ModalBody>
                        <div style={{ minHeight: 200 }}>
                            <div className="mb-4 fs-13 mt-4">
                                This product will be availlable for customers to book on the days
                                selected below.
                            </div>

                            <div className="mb-4">
                                <Checkbox
                                    className="size-lg border-active"
                                    indeterminate={calendarDaysIndeterminate}
                                    onChange={onCheckAllCalendarDaysForBookingChange}
                                    checked={checkAllCalendarDaysForBooking}>
                                    Select all
                                </Checkbox>
                            </div>
                            <Form.Item
                                value={calendarDays}
                                name="days"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select one'
                                    }
                                ]}>
                                <Checkbox.Group
                                    className="type-tile"
                                    options={calendarDaysForBooking.map((column) => ({
                                        label: column.label,
                                        value: column.label
                                    }))}
                                    // options={calendarDaysForBooking}
                                    value={calendarDays}
                                    onChange={onCalendarDaysForBookingChange}
                                />
                            </Form.Item>

                            {/* Suggest a specific day, only for within a day flow */}
                            {props.current_product?.day_type === 'within_day' && (
                                <div>
                                    <div className="fs-13 fw-400 mb-2">Suggest a specific day</div>
                                    <Form.Item
                                        className="mb-10px"
                                        name={'suggest_specific_day'}
                                        rules={[
                                            {
                                                required:
                                                    props.current_product?.day_type ===
                                                    'within_day',
                                                message: 'Please select'
                                            }
                                        ]}
                                        style={{ width: 'fit-content' }}>
                                        <Select
                                            className="form-control-alt d-inline-block"
                                            dropdownClassName="type-rich"
                                            placeholder="Select Day"
                                            size="large">
                                            {getDropDownData()}
                                        </Select>
                                    </Form.Item>
                                </div>
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-start">
                        <Button
                            disabled={props.loading}
                            color="primary"
                            type="submit"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            //onClick={(e) => handleSecondCreateCalendar(e)}
                        >
                            Update Calender & Continue
                            {props.loading && <Spinner className="ml-2" size="sm" color="light" />}
                        </Button>
                        <Button
                            color="dark"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            onClick={() => {
                                setIsCanceling(true);
                                setConfirmationModalOpenState(true);
                            }}>
                            Cancel
                        </Button>
                        <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
                    </ModalFooter>
                </Form>
            </Modal>
            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setModalOpenState={setConfirmationModalOpenState}
                description={
                    isCanceling
                        ? 'you want to cancel'
                        : 'This update will be reflected on all calendars that this product is associated with.'
                }
                handleConfirmation={() => {
                    isCanceling ? handleCancelCalendarDaysBooking() : onConfirmYes();
                    setConfirmationModalOpenState(false);
                }}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    product_categories: state.calendar.product_categories,
    //update_calendar_product: state.calendar.update_calendar_product,
    get_calendar_data: state.calendar.get_calendar_data,
    selected_existing_product: state.product.selected_existing_product,
    loading: state.loading,
    current_product: state.product.currentProduct,
    package_detail: state.product.package_detail,
    calendar_products_list: state.calendar.get_all_calendar_products?.data,
    current_product_flow: state.product.current_product_flow
});

const mapDispatch = (dispatch) => ({
    updateProductsCalendarDay: (data, id, silently) =>
        dispatch(updateProductsCalendarDay(data, id, silently)),
    resetCalenderReducer: (data) => dispatch(resetCalenderReducer(data)),
    deleteAddOnProduct: (data) => dispatch(deleteAddOnProduct(data)),
    // selectedExistingProduct: (data) => dispatch(selectedExistingProduct(data)),
    getCalendarsById: (id) => dispatch(getCalendarsById(id)),
    resetProductReducer: (data) => dispatch(resetProductReducer(data)),
    attatchExistingProductAsAddon: (data) => dispatch(attatchExistingProductAsAddon(data)),
    duplicateExistingProductAsAddon: (data) => dispatch(duplicateExistingProductAsAddon(data)),
    getAllCalendarProducts: (id) => dispatch(getAllCalendarProducts(id))
});

CalendarAllDaysModal.propTypes = {
    isModalOpen: PropTypes.bool,
    updateProductsCalendarDay: PropTypes.func,
    current_product: PropTypes.object,
    // update_customer_book: PropTypes.object,
    setAddOnProductItineraryTimeModal: PropTypes.func,
    setAddProductsProductTypeModal: PropTypes.func,
    resetCalenderReducer: PropTypes.func,
    updateAndContinue: PropTypes.func,
    //update_calendar_product: PropTypes.object,
    isOpen: PropTypes.bool,
    closeCalendarModal: PropTypes.func,
    deleteAddOnProduct: PropTypes.func,
    get_calendar_data: PropTypes.object,
    selected_existing_product: PropTypes.bool,
    selectedExistingProduct: PropTypes.func,
    getCalendarsById: PropTypes.func,
    loading: PropTypes.bool,
    setEditMainProductInitModal: PropTypes.func,
    resetProductReducer: PropTypes.func,
    package_detail: PropTypes.any,
    calendar_products_list: PropTypes.any,
    attatchExistingProductAsAddon: PropTypes.func,
    duplicateExistingProductAsAddon: PropTypes.func,
    current_product_flow: PropTypes.any,
    getAllCalendarProducts: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatch)(CalendarAllDaysModal));
