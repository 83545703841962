/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import './Calendar.scss';
import CreateMainProduct from './create-main-product';
import AccomodationMinipage from './accomodation/accomodation-minipage';
import Feedback from '../user-feedback';
import ModalComponent from '../../molecules/calendar/add-an-itinerary/index.js';
import { toggleAccessCalendarModal } from '../../../store/actions/accessCalendar';
import AccessCustomerCalendarModal from '../../../components/organisms/accessCustomerCalendarModal/AccessCustomerCalendarModal';
// import FundsConfirmation from 'components/molecules/calendar/booking-tickets/funds-confirmation';

// import TimeComponent from '../../molecules/time/time.js';
// import add_circle from '../../../assets/images/add_circle.svg';
import FullCalendar from '../../molecules/calendar/fullCalendar';
import warningIcon from '../../../assets/images/warning-icon.svg';
import {
    Modal as AntModal,
    Form,
    Select,
    Checkbox,
    Input,
    Upload,
    TimePicker,
    DatePicker,
    Image,
    Radio,
    Tooltip,
    Popover,
    Drawer,
    Spin
} from 'antd';
import TextDropDown from '../../atoms/text-dropdown';
import Caret from '../../../assets/images/caret.svg';
import Down from '../../../assets/images/down-caret.svg';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { connect } from 'react-redux';
import TravelJinniIcon from '../../atoms/icons/travel-jinni';

import ImgCrop from 'antd-img-crop';
import {
    getBusinessNameLogo,
    updateBusinessNameLogo,
    updateBusinessNameLogoLoading_,
    updateCalendarTitle,
    getCalendarsById,
    updateCalendarLength,
    updateCalendarLgSuccess,
    resetCalenderReducer,
    resetProductReducer,
    setUpdateProductFields_
} from 'store/actions';
import { API_URL } from '../../../utils/constants';
import NotificationsGroup from '../notifications';
import AddProductsInitModal from './add-on-products-modal/add-products-init';
import CalendarAllDaysModal from './add-on-products-modal/calendar-all-days';
import AddProductsProductTypeModal from './add-on-products-modal/add-products-product-type';
import CalendarSalesModal from './calendar-insights/calendar-sales';
import CustomerInterestModal from './calendar-insights/customer-interest';
import CustomerDemandModal from './calendar-insights/customer-demand';
import AddItineraryTimeModal from './add-on-products-modal/add-itenary-time';
import CalendarRatingModal from './increase-rating/calendar-rating';
import AvailableHoursModal from './increase-rating/available-hours';
import ProductQualityModal from './increase-rating/product-quality';
import AddAnItineraryModal from './add-on-products-modal/add-an-itinerary';
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import LoaderComponent from 'components/atoms/loader';
import { Loader, Notification, StarRating } from 'components/atoms';
import {
    ExistingMainProductModal,
    CreatePackageDrawer,
    AddNewMainProductModal,
    ItineraryForm
} from './add-main-product-modal/index';
import { DuplicateCalendarModal } from 'components/molecules';
import { useLocation, withRouter } from 'react-router-dom';
import CreateMainProductInitModal from './add-main-product-modal/create-main-product-init-modal';
import EditMainProductInitModal from './add-main-product-modal/edit-main-product-init-modal';
import { ComponentPermissionContext } from 'context/rolesandpermission';
// import { assignComponentPermission } from 'utils/rolesandpermission/accessright';

const CalendarComponent = (props) => {
    const { showUpdateProductFields, setUpdateProductFields_ } = props;
    const { assignComponentPermission } = useContext(ComponentPermissionContext);

    useEffect(() => {
        if (Object.keys(showUpdateProductFields).length > 0) {
            if (showUpdateProductFields?.editDays) setSelectCalendarDaysBookingModal(true);
            else if (showUpdateProductFields?.editBookingTimePeriods)
                setAddOnProductItineraryTimeModal(true);
            else setAddProductItineraryModal(true);
            setTimeout(() => {
                // Clear the redux state here
                setUpdateProductFields_({});
            }, 3000);
        }
    }, [showUpdateProductFields]);

    const calendarTitleInputRef = React.useRef(null);
    const [openModal, setopenModal] = useState(false);
    const [openCreateMainProduct, setOpenCreateMainProduct] = useState(false);
    const [openActivityModal, setOpenActivityModal] = useState(false);
    const [openAccomodationMinipage, setOpenAccomodationMinipage] = useState(false);
    const [openMealsAndDrinks, setOpenMealsAndDrinks] = useState(false);
    const [addProductsInitModal, setAddProductsInitModal] = useState(false);
    const [addProductsProductTypeModal, setAddProductsProductTypeModal] = useState(false);
    const [addOnProductItineraryTimeModal, setAddOnProductItineraryTimeModal] = useState(false);

    const [selectCalendarDaysBookingModal, setSelectCalendarDaysBookingModal] = useState(false);
    const [eventModal, setEventModal] = useState(false);
    const [eventDestination, setEventDestination] = useState();
    const [prefferedDate, setPrefferedDate] = useState(false);

    const [fetchedCalendarTitle, setFetchedCalendarTitle] = useState('');
    const [editingCalendarTitle, setEditingCalendarTitle] = useState(false);
    const [savingCalendarTitle, setSavingCalendarTitle] = useState(false);

    const [increaseCalendarRatingModal, setIncreaseCalendarRatingModal] = useState(false);
    const [availableHoursModal, setAvailableHoursModal] = useState(false);
    const [productQualityModal, setProductQualityModal] = useState(false);
    const [firstCreateMainProductModal, setFirstCreateMainProductModal] = useState(false);

    const [goBackToDashboardModal, setGoBackToDashboardModal] = useState(false);
    const [calendarLengthPopverVisible, setCalendarLengthPopverVisible] = useState(false);
    const [duplicateCalendarModalPopup, setDuplicateCalendarModalPopup] = useState(false);
    const [createMainProductModal, setCreateMainProductModal] = useState(false);
    const [createMainProductPackageModal, setCreateMainProductPackageModal] = useState(false);
    const [createYourPackageDrawer, setCreateYourPackageDrawer] = useState(false);
    // const [editChangeMainProductModal, setEditChangeMainProductModal] = useState(false);
    const [addAnItineraryModal, setAddAnItineraryModal] = useState(false);
    const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
    const [calendarNameUpdated, setCalendarNameUpdated] = useState(false);
    // const [addProductFromExistingProducts, setAddProductFromExistingProducts] = useState(1);
    const [currentWindowWidth, setCurrentWindowWidth] = useState(window.innerWidth);
    const [calendarMenuVisibility, setCalendarMenuVisibility] = useState(false);
    const [removed, setRemoved] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [businessForm] = Form.useForm();

    const [changeWithNewProduct, setChangeWithNewProduct] = useState(false);

    // const [conModal, openConModal] = useState(false);

    // Calendar Insights
    // const [calendarInsightsTooltipVisibility, setCalendarInsightsTooltipVisibility] = useState(
    //     false
    // );
    const [viewCalendarSales, setViewCalendarSales] = useState(false);
    const [viewCustomerDemand, setViewCustomerDemand] = useState(false);
    const [viewCustomerInterest, setViewCustomerInterest] = useState(false);

    const [loadingCalendarById, setLoadingCalendarById] = useState(false);

    const { Option } = Select;
    const { RangePicker } = DatePicker;

    const selectDestinationOptions = [
        { label: 'Dubai Creeks', value: 'Dubai Creeks' },
        { label: 'Burj al khalifa', value: 'Burj al khalifa' },
        { label: 'Dubai Gold Souk', value: 'Dubai Gold Souk' },
        { label: 'Emirats Tower', value: 'Emirats Tower', disabled: true }
    ];

    //Options to show in Add more products form
    const productsAvailabilityType = [
        {
            name: 'Products available all day',
            value: 'all_day'
        },
        {
            name: 'Products available within the day',
            value: 'within_day'
        }
    ];

    const search = useLocation().search;
    const increaseRatingParam = new URLSearchParams(search).get('increaseRating');

    const [addProductInitModalData, setAddProductInitModalData] = useState(null);
    const [addProductItineraryModal, setAddProductItineraryModal] = useState(false);
    const [createMainProductInitModal, setCreateMainProductInitModal] = useState(false);
    const [editMainProductInitModal, setEditMainProductInitModal] = useState(false);

    const [form] = Form.useForm();
    const {
        business_data,
        getBusinessNameLogo,
        get_calendar_data,
        update_calendar_length,
        updateCalendarLgSuccess
    } = props;

    useEffect(() => {
        if (increaseRatingParam && increaseRatingParam == 'true' && !increaseCalendarRatingModal) {
            setIncreaseCalendarRatingModal(true);
        }

        //fetching the calendar product details
        if (props?.match?.params?.id) {
            setLoadingCalendarById(true);
            props
                .getCalendarsById(props.match.params.id)
                .finally(() => setLoadingCalendarById(false));
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            adjustCalendarNameFontSize(24);
        }, 1000);
        if (business_data && business_data.data) {
            if (business_data?.data?.business_logo_url !== null) {
                setFileList([
                    {
                        uid: '-1',
                        name: business_data?.data?.business_logo_url?.substring(
                            business_data?.data?.business_logo_url.lastIndexOf('/') + 1
                        ),
                        status: 'done',
                        url: business_data?.data?.business_logo_url,
                        thumbUrl: business_data?.data.business_logo_url
                    }
                ]);
            }
            if (business_data?.data?.business_trading_name)
                businessForm.setFields([
                    {
                        name: 'business_trading_name',
                        value: business_data.data.business_trading_name
                    }
                ]);
        } else {
            getBusinessNameLogo();
        }
        if (get_calendar_data) {
            setFetchedCalendarTitle(get_calendar_data?.data?.title);
        }
        if (update_calendar_length?.success) {
            setCalendarLengthPopverVisible(false);
            updateCalendarLgSuccess(false);
        }
        function handleResize() {
            setCurrentWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        if (editingCalendarTitle) {
            calendarTitleInputRef.current.focus();
        }
    }, [
        business_data,
        businessForm,
        getBusinessNameLogo,
        editingCalendarTitle,
        get_calendar_data,
        update_calendar_length,
        updateCalendarLgSuccess
    ]);

    const onFinishCalendarLength = (values) => {
        if (values.no_of_days === '0' && values.no_of_weeks === '0') {
            return form.setFields([
                {
                    name: `no_of_days`,
                    errors: ['A calendar must be al least one day long']
                }
            ]);
        }

        form.setFields([
            {
                name: `no_of_days`,
                errors: []
            },
            {
                name: `no_of_weeks`,
                errors: []
            }
        ]);
        props.updateCalendarLength(values, props.match.params.id);
    };
    const authToken = localStorage.getItem('token');
    const uploadBusinessLogo = {
        name: 'media',
        action: `${API_URL}/media-library`,
        headers: {
            authorization: `Bearer ${authToken}`
        },
        onChange(info) {
            setFileList(info.fileList);
            setRemoved(false);
            if (info.file.status === 'uploading') {
                props.updateBusinessNameLogoLoading(true);
            } else if (info.file.status === 'done') {
                props.updateBusinessNameLogo({ business_logo_url: info.file.response.data.url });
            } else if (info.file.status === 'removed') {
                props.updateBusinessNameLogo({ business_logo_url: null });
                setRemoved(true);
            } else if (info.file.status === 'error') {
                props.updateBusinessNameLogoLoading(false);
                //  message.error(`${info.file.name} file upload failed.`);
                Notification('error', 'Something went wrong! try again later');
            }
        }
    };

    const heirachy = [
        {
            name: 'Products avaiablable all day',
            value: 'all_day',
            child: [
                {
                    name: 'Accommodation',
                    value: 'accommodation',
                    category: 'select',
                    grandChild: [
                        'Villa / Mansion',
                        'Hotel',
                        'Apartment',
                        'Lodge',
                        'Camping',
                        'Bread and Basket',
                        'select',
                        'Hostel',
                        'Log Cabin',
                        'Country Home',
                        'Timeshare Rentals',
                        'Cottage',
                        'Other'
                    ]
                },
                {
                    name: 'Transportation',
                    value: 'transportation',
                    category: 'select',
                    grandChild: [
                        'Airport Transfer / Pickup',
                        'Car Rental',
                        'Chauffer Service',
                        'Other'
                    ]
                },
                {
                    name: 'Meals & Drinks',
                    value: 'meals_and_drinks',
                    category: 'select',
                    grandChild: [
                        'Personal Chef Service',
                        'Groceries Shopper',
                        'Traditional Food Delivery',
                        'Other'
                    ]
                },
                {
                    name: 'Personal Care',
                    value: 'personal_care',
                    category: 'select',
                    grandChild: ['Laundry Service', 'Child Minder Service', 'Other']
                }
            ]
        },
        {
            name: 'Products available within the day',
            value: 'within_day',
            child: [
                {
                    name: 'Activity',
                    value: 'activity',
                    category: 'select',
                    grandChild: ['Itinerary', 'Other']
                },
                {
                    name: 'Meals & Drinks',
                    value: 'meals_and_drinks',
                    category: 'select',
                    grandChild: ['Resturant Booking', 'Other']
                },
                {
                    name: 'Guide',
                    value: 'guide',
                    category: 'select',
                    grandChild: ['Market Guide', 'Nightlife Guide', 'Meet The Locals', 'Other']
                },
                {
                    name: 'Personal Care',
                    value: 'personal_care',
                    category: 'select',
                    grandChild: ['Spa / Wellness', 'Other']
                }
            ]
        }
    ];

    const onOpenAnotherDrawer = (e) => {
        if (e === 'mealsAndDrinks') {
            setOpenMealsAndDrinks(!openMealsAndDrinks);
        }
    };

    function handleCreateMainProduct() {
        setOpenCreateMainProduct(!openCreateMainProduct);
    }

    function handleAccomodationMinipage() {
        setOpenAccomodationMinipage(!openAccomodationMinipage);
    }

    function handleActivityModal() {
        setOpenActivityModal(!openActivityModal);
    }

    function checkIfValidCalendarName(e) {
        if (e.target.value.trim() !== '') {
            setCalendarNameUpdated(true);
            adjustCalendarNameFontSize(0);
        } else {
            setCalendarNameUpdated(false);
            adjustCalendarNameFontSize(24);
        }
    }

    function adjustCalendarNameFontSize(calendarNameExtraWidth) {
        const inputElementWidth = 120;
        const minAllowedFontSize = 8;
        const maxAllowedFontSize = 20;
        const inputElement = document.getElementById('main_calendar_name_input');
        const inputText = document.createElement('pre');
        document.body.appendChild(inputText);
        inputText.style.fontFamily = 'Montserrat,sans-serif';
        inputText.style.textTransform = 'uppercase';
        inputText.style.fontSize = maxAllowedFontSize + 'px';
        inputText.style.height = 'auto';
        inputText.style.width = 'auto';
        inputText.style.position = 'absolute';
        inputText.style.left = -1000 + 'px';
        inputText.style.top = -1000 + 'px';
        inputText.style.whiteSpace = 'no-wrap';
        inputText.innerHTML = inputElement ? inputElement.value : '';
        let inputStringWidth = Math.ceil(inputText.clientWidth);
        if (inputStringWidth > inputElementWidth + calendarNameExtraWidth) {
            let acceptedFontSize =
                (maxAllowedFontSize * (inputElementWidth + calendarNameExtraWidth)) /
                inputStringWidth;
            if (acceptedFontSize > minAllowedFontSize) {
                inputElement.style.fontSize =
                    (maxAllowedFontSize * (inputElementWidth + calendarNameExtraWidth)) /
                        inputStringWidth +
                    'px';
            } else {
                inputElement.style.fontSize = minAllowedFontSize + 'px';
            }
        } else {
            if (inputElement) {
                inputElement.style.fontSize = maxAllowedFontSize + 'px';
            }
        }
        document.body.removeChild(inputText);
    }

    const onBusinessFinish = () => {
        setCalendarNameUpdated(false);
        adjustCalendarNameFontSize(24);
        let data = businessForm.getFieldsValue();
        props.updateBusinessNameLogo(data);
    };

    const backToDashboard = () => {
        return props.history.push('/home');
    };

    const onCalendarTitleChange = (e) => {
        if (e.keyCode === 13) {
            setSavingCalendarTitle(true);
            let data = { title: e.target.value };
            props.updateCalendarTitle(data, props.match.params.id);
            setTimeout(() => {
                setSavingCalendarTitle(false);
                setFetchedCalendarTitle(e.target.value);
                setEditingCalendarTitle(false);
            }, 1000);
        } else if (e.keyCode === 27) {
            setEditingCalendarTitle(false);
        }
    };
    const onCalendarTitleBlur = (e) => {
        setSavingCalendarTitle(true);
        let data = { title: e.target.value };
        props.updateCalendarTitle(data, props.match.params.id);
        setTimeout(() => {
            setSavingCalendarTitle(false);
            setFetchedCalendarTitle(e.target.value);
            setEditingCalendarTitle(false);
        }, 1000);
    };

    // All DOMS that can be used multiple times
    const calendarLogoWrap = (
        <ImgCrop rotate>
            <Upload
                //  defaultFileList={[...fileList]}
                fileList={fileList}
                {...uploadBusinessLogo}
                listType="picture-card"
                className={`type-profile-alt _50x50 calendar-upload-logo-container  ${assignComponentPermission(
                    'calendar-create-calendar'
                )} `}>
                {!props?.business_data?.data?.business_logo_url || removed ? (
                    <div className="add-logo" role="button" tabIndex="0">
                        Add Logo
                    </div>
                ) : (
                    <div className="position-relative">
                        <div className="no-logo-wrapper"></div>
                        <Tooltip title="Update Logo">
                            <div className="edit-logo" role="button" tabIndex="0">
                                <svg
                                    width="33"
                                    height="32"
                                    viewBox="0 0 33 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d)">
                                        <circle cx="16.6665" cy="12" r="12" fill="white" />
                                        <path
                                            d="M10.5421 14.3111L9.69765 17.9555C9.66852 18.0888 9.66951 18.2268 9.70057 18.3596C9.73162 18.4924 9.79195 18.6166 9.87714 18.7231C9.96233 18.8296 10.0702 18.9157 10.193 18.9751C10.3157 19.0346 10.4502 19.0658 10.5865 19.0667C10.6501 19.0731 10.7141 19.0731 10.7776 19.0667L14.4443 18.2222L21.4843 11.2089L17.5554 7.28888L10.5421 14.3111Z"
                                            fill="#A2792C"
                                        />
                                        <path
                                            d="M23.6976 7.69778L21.0754 5.07556C20.903 4.90403 20.6697 4.80774 20.4265 4.80774C20.1833 4.80774 19.95 4.90403 19.7776 5.07556L18.3198 6.53333L22.2443 10.4578L23.702 9C23.7874 8.91426 23.8549 8.81253 23.9009 8.70065C23.9468 8.58877 23.9703 8.46892 23.9699 8.34797C23.9694 8.22702 23.9452 8.10733 23.8985 7.99576C23.8518 7.8842 23.7835 7.78294 23.6976 7.69778Z"
                                            fill="#A2792C"
                                        />
                                    </g>
                                    <defs>
                                        <filter
                                            id="filter0_d"
                                            x="0.666504"
                                            y="0"
                                            width="32"
                                            height="32"
                                            filterUnits="userSpaceOnUse"
                                            colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="BackgroundImageFix"
                                                result="effect1_dropShadow"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect1_dropShadow"
                                                result="shape"
                                            />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                        </Tooltip>
                    </div>
                )}
            </Upload>
        </ImgCrop>
    );

    const calendarNameWrap = (
        <Form form={businessForm} onSubmitCapture={onBusinessFinish}>
            <div
                className={`event-name-wrapper ${assignComponentPermission(
                    'calendar-create-calendar'
                )}`}>
                <div className="event-input-wrapper">
                    <Form.Item
                        className={`mb-0 ${calendarNameUpdated ? 'calendar-name-updated' : ''}`}
                        name="business_trading_name"
                        initialValue={props?.business_data?.data?.business_trading_name}>
                        <Input
                            id="main_calendar_name_input"
                            className="event-input"
                            placeholder="Enter Name"
                            bordered={false}
                            onInput={(e) => checkIfValidCalendarName(e)}
                        />
                    </Form.Item>
                    <Form.Item className="mb-0 event-input-action-wrapper">
                        <span
                            className="event-input-action"
                            onClick={onBusinessFinish}
                            role="button"
                            tabIndex="0"
                            title="Save"
                            type="submit">
                            <ArrowRightOutlined />
                        </span>
                    </Form.Item>
                </div>
                <div className="event-name-footer">Powered by Travel Jinni</div>
            </div>
        </Form>
    );

    const calendarTitleAndLengthWrap = (
        <>
            <div className="d-flex flex-nowrap flex-grow-1">
                {editingCalendarTitle ? (
                    <>
                        <Input
                            className="flex-grow-1 d-block"
                            size="small"
                            ref={calendarTitleInputRef}
                            defaultValue={fetchedCalendarTitle}
                            disabled={savingCalendarTitle}
                            onKeyUp={onCalendarTitleChange.bind(this)}
                            onBlur={onCalendarTitleBlur.bind(this)}
                            style={{ maxWidth: 400 }}
                        />
                        {savingCalendarTitle ? (
                            <Spin className="ml-2" indicator={<LoadingOutlined spin />} />
                        ) : null}
                    </>
                ) : (
                    <>
                        <Tooltip title={fetchedCalendarTitle}>
                            <div className="fs-14 fw-500 text-primary text-truncate">
                                {fetchedCalendarTitle}
                            </div>
                        </Tooltip>
                        <div className="ml-2">
                            <span
                                className="d-block lh-1 p-1 rounded-circle shadow"
                                role="button"
                                tabIndex="0"
                                onClick={() => {
                                    setEditingCalendarTitle(true);
                                }}
                                onKeyUp={() => {
                                    setEditingCalendarTitle(true);
                                }}>
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="7" cy="7" r="7" fill="white" />
                                    <path
                                        d="M3.42753 8.34807L2.93493 10.474C2.91794 10.5517 2.91852 10.6322 2.93664 10.7097C2.95475 10.7872 2.98994 10.8596 3.03964 10.9217C3.08933 10.9838 3.15228 11.0341 3.22387 11.0687C3.29547 11.1034 3.3739 11.1217 3.45345 11.1221C3.49052 11.1259 3.52787 11.1259 3.56493 11.1221L5.70382 10.6295L9.81049 6.53844L7.51864 4.25177L3.42753 8.34807Z"
                                        fill="#A2792C"
                                    />
                                    <path
                                        d="M11.1014 4.49042L9.57176 2.96079C9.4712 2.86073 9.33511 2.80457 9.19324 2.80457C9.05138 2.80457 8.91529 2.86073 8.81473 2.96079L7.96436 3.81116L10.2536 6.10042L11.104 5.25005C11.1537 5.20003 11.1932 5.1407 11.22 5.07543C11.2468 5.01017 11.2605 4.94025 11.2602 4.8697C11.26 4.79914 11.2458 4.72933 11.2186 4.66425C11.1913 4.59917 11.1515 4.5401 11.1014 4.49042Z"
                                        fill="#A2792C"
                                    />
                                </svg>
                            </span>
                        </div>
                    </>
                )}
            </div>
            <Popover
                overlayClassName="popover-container popover-regular popover-dark-bg"
                placement={currentWindowWidth >= 992 ? 'bottom' : 'bottomLeft'}
                trigger="click"
                visible={calendarLengthPopverVisible}
                onVisibleChange={() => setCalendarLengthPopverVisible(!calendarLengthPopverVisible)}
                content={
                    <div>
                        {true ? (
                            <>
                                <Form
                                    form={form}
                                    name="calendar_length"
                                    onFinish={onFinishCalendarLength}>
                                    <div className="fs-15 text-khaki fw-500 ff-special mb-2 fst-italic">
                                        Change Calendar Length
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center justify-content-between mx-n2">
                                        <div className="px-2 col-12 mb-2">
                                            <Form.Item
                                                name="no_of_weeks"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select no of week'
                                                    }
                                                ]}>
                                                <Select
                                                    className="tooltip-select d-block w-100"
                                                    placeholder="Select Weeks"
                                                    // style={{ minWidth: 95 }}
                                                    getPopupContainer={(trigger) =>
                                                        trigger.parentElement
                                                    }>
                                                    <Option value="0">0 Weeks</Option>
                                                    <Option value="1">1 Week</Option>
                                                    <Option value="2">2 Weeks</Option>
                                                    <Option value="3">3 Weeks</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="px-2 col-12 mb-2">
                                            <Form.Item
                                                name="no_of_days"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select no of days'
                                                    }
                                                ]}>
                                                <Select
                                                    className="tooltip-select d-block w-100"
                                                    placeholder="Select Days"
                                                    // style={{ minWidth: 95 }}
                                                    getPopupContainer={(trigger) =>
                                                        trigger.parentElement
                                                    }>
                                                    <Option value="0">0 Days</Option>
                                                    <Option value="1">1 Day</Option>
                                                    <Option value="2">2 Days</Option>
                                                    <Option value="3">3 Days</Option>
                                                    <Option value="4">4 Days</Option>
                                                    <Option value="5">5 Days</Option>
                                                    <Option value="6">6 Days</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mx-n2 mb-2">
                                        <div className="px-2 col-6">
                                            <Button
                                                color="tooltip"
                                                className="px-2"
                                                block
                                                type="submit"
                                                // style={{ minWidth: 95 }}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                        <div className="px-2 col-6">
                                            <Button
                                                color="tooltip"
                                                className="px-2"
                                                block
                                                // style={{ minWidth: 95 }}
                                                onClick={() => {
                                                    const values = form.getFieldsValue();
                                                    if (values.no_of_weeks === undefined) {
                                                        return form.setFields([
                                                            {
                                                                name: `no_of_weeks`,
                                                                errors: ['Please enter no of weeks']
                                                            }
                                                        ]);
                                                    }
                                                    if (values.no_of_days === undefined) {
                                                        return form.setFields([
                                                            {
                                                                name: `no_of_days`,
                                                                errors: ['Please enter no of days']
                                                            }
                                                        ]);
                                                    }

                                                    if (
                                                        values.no_of_days === '0' &&
                                                        values.no_of_weeks === '0'
                                                    ) {
                                                        return form.setFields([
                                                            {
                                                                name: `no_of_days`,
                                                                errors: [
                                                                    'A calendar must be al least one day long'
                                                                ]
                                                            }
                                                        ]);
                                                    }
                                                    form.setFields([
                                                        {
                                                            name: `no_of_days`,
                                                            errors: []
                                                        },
                                                        {
                                                            name: `no_of_weeks`,
                                                            errors: []
                                                        }
                                                    ]);

                                                    setCalendarLengthPopverVisible(false);
                                                    setDuplicateCalendarModalPopup(true);
                                                }}>
                                                Duplicate
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        ) : (
                            <>
                                <div className="fs-15 text-khaki fw-500 ff-special mb-2 fst-italic">
                                    Calendar Length Updated
                                </div>
                                <div className="mb-2 d-flex justify-content-center">
                                    <Button
                                        color="tooltip"
                                        className="px-2"
                                        style={{ minWidth: 95 }}>
                                        OK
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                }>
                <span
                    className="d-inline-flex text-nowrap fs-12 text-primary pb-1"
                    role="button"
                    tabIndex="0">
                    <span>
                        {props.get_calendar_data?.data?.no_of_weeks} Week,{' '}
                        {props.get_calendar_data?.data?.no_of_days} Days
                    </span>
                    <div className="ml-2">
                        <span className="d-block lh-1 p-1 rounded-circle shadow">
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7" cy="7" r="7" fill="white" />
                                <path
                                    d="M3.42753 8.34807L2.93493 10.474C2.91794 10.5517 2.91852 10.6322 2.93664 10.7097C2.95475 10.7872 2.98994 10.8596 3.03964 10.9217C3.08933 10.9838 3.15228 11.0341 3.22387 11.0687C3.29547 11.1034 3.3739 11.1217 3.45345 11.1221C3.49052 11.1259 3.52787 11.1259 3.56493 11.1221L5.70382 10.6295L9.81049 6.53844L7.51864 4.25177L3.42753 8.34807Z"
                                    fill="#A2792C"
                                />
                                <path
                                    d="M11.1014 4.49042L9.57176 2.96079C9.4712 2.86073 9.33511 2.80457 9.19324 2.80457C9.05138 2.80457 8.91529 2.86073 8.81473 2.96079L7.96436 3.81116L10.2536 6.10042L11.104 5.25005C11.1537 5.20003 11.1932 5.1407 11.22 5.07543C11.2468 5.01017 11.2605 4.94025 11.2602 4.8697C11.26 4.79914 11.2458 4.72933 11.2186 4.66425C11.1913 4.59917 11.1515 4.5401 11.1014 4.49042Z"
                                    fill="#A2792C"
                                />
                            </svg>
                        </span>
                    </div>
                </span>
            </Popover>
        </>
    );

    const calendarStatusWrap = () => {
        const { get_calendar_data } = props;
        let calendarStatus = get_calendar_data?.data?.calendar_status
            ? get_calendar_data?.data?.calendar_status
            : 'draft';
        calendarStatus = calendarStatus.charAt(0).toUpperCase() + calendarStatus.slice(1);
        return (
            <Tooltip
                placement={currentWindowWidth >= 768 ? 'top' : 'right'}
                title={
                    <div className="text-center">
                        <div className="fw-600">Calendar Status:</div>
                        <div className="text-secondary fs-12">
                            {calendarStatus === 'Live' ? 'A' : 'No'} link has been generated for
                            your calendar and it is {calendarStatus === 'Live' ? 'now' : 'not'}{' '}
                            active for customers to access
                        </div>
                    </div>
                }>
                <div className="fs-14 text-nowrap">
                    <span
                        className={`calendar_activity-status __${calendarStatus
                            .toLowerCase()
                            .replace(/ /g, '_')}`}></span>
                    <span className="fs-14 text-gray">{calendarStatus}</span>
                </div>
            </Tooltip>
        );
    };
    const customerStatusWrap = () => {
        const { get_calendar_data } = props;
        let customerStatus = get_calendar_data?.data?.customer_status
            ? get_calendar_data?.data?.customer_status
            : 'not-in-use';
        let showCustomerStatus = customerStatus == 'not-in-use' ? 'Not In Use' : 'In Use';
        return (
            <Tooltip
                placement={currentWindowWidth >= 768 ? 'top' : 'right'}
                title={
                    <div className="text-center">
                        <div className="fw-600">Customer Status:</div>
                        <div className="text-secondary fs-12">
                            Your calendar is{' '}
                            {customerStatus === 'not-in-use' ? 'not currently' : 'currently'} in use
                            by customers.
                        </div>
                    </div>
                }>
                <div className="fs-14 text-nowrap">
                    <span
                        className={`calendar_activity-status __${showCustomerStatus
                            .toLowerCase()
                            .replace(/ /g, '_')}`}></span>
                    <span className="fs-14 text-gray">{showCustomerStatus}</span>
                </div>
            </Tooltip>
        );
    };

    const PoweredByCreditWrap = () => {
        return (
            <div className="d-flex flex-nowrap align-items-center mx-n2">
                <div className="px-2">
                    <TravelJinniIcon />
                </div>
                <div className="px-2">
                    <div className="powered-credits text-nowrap">Powered by Travel Jinni</div>
                </div>
            </div>
        );
    };

    function restingCalendarReducers() {
        props.resetCalenderReducer({
            //create_addon_product: true,
            // update_customer_book: true,
            update_calendar_product: true,
            //selected_existing_product: true,
            update_products_personal_info: true
        });
        props.resetProductReducer({
            currentProduct: true,
            selected_existing_product: true,
            attached_duplicated_product: true,
            current_product_flow: true
        });
    }

    const resettingProductReducers = () => {
        props.resetProductReducer({
            package_detail: true,
            product_detail: true,
            currentProduct: true,
            selected_existing_product: true,
            attached_duplicated_product: true,
            current_product_flow: true
        });
    };

    function onClickAddMoreProduct() {
        setAddProductsInitModal(true);
        setAddProductInitModalData(null);
        restingCalendarReducers();
        resettingProductReducers();
    }

    function createMainProduct() {
        setCreateMainProductInitModal(true);
        restingCalendarReducers();
        resettingProductReducers();
    }

    const mainProduct =
        get_calendar_data?.data?.mainProduct &&
        Array.isArray(get_calendar_data?.data?.mainProduct) &&
        get_calendar_data?.data?.mainProduct.length > 0
            ? get_calendar_data?.data?.mainProduct[0]
            : null;
    const mainPackage =
        get_calendar_data?.data?.mainPackage &&
        Array.isArray(get_calendar_data?.data?.mainPackage) &&
        get_calendar_data?.data?.mainPackage.length > 0
            ? get_calendar_data?.data?.mainPackage[0]
            : null;

    return (
        <div
            className={`e2e-fix bg-calendar bg-calendar-padded d-flex flex-column ${assignComponentPermission(
                'calendar'
            )}`}
            style={{ minHeight: 'calc(100vh - 97px)' }}>
            {/* loading overlay untill loading the get calendar by id */}
            {(loadingCalendarById || props.acc_loading || props.update_business_data_loading) && (
                <LoaderComponent typeTransparent />
            )}

            <AccessCustomerCalendarModal
                calendarId={get_calendar_data?.data?.uuid || ''}
                title="Access Customer Calendar"
                activeTab={'1'}
                createMainProduct={createMainProduct}
            />

            <Row>
                <Col xs={{ size: 12, order: 2 }} xl={{ order: 1 }}>
                    {/* Master Event Wrapper */}
                    <div className="master-event-wrapper">
                        {/* Calendar Menu for mobile */}
                        {currentWindowWidth < 768 && calendarMenuVisibility ? (
                            <div className="calendar-menu-wrapper">
                                <div
                                    className="calendar-menu-overlay"
                                    onClick={() => setCalendarMenuVisibility(false)}></div>
                                <div className="calendar-menu-content">
                                    <div className="clearfix">
                                        <button
                                            className="float-left op-full close mt-2"
                                            type="button"
                                            aria-label="close"
                                            onClick={() => setCalendarMenuVisibility(false)}>
                                            <span className="close-bg-icon"></span>
                                        </button>
                                        <div className="float-right">
                                            <div className="d-flex align-items-center flex-nowrap mx-n2">
                                                <div className="px-2">{calendarLogoWrap}</div>
                                                <div className="px-2">{calendarNameWrap}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="calendar-menu-seprator"></div>
                                    <div className="py-2">{calendarTitleAndLengthWrap}</div>
                                    <div className="calendar-menu-seprator"></div>
                                    <div className="py-2">
                                        <div className="d-flex flex-nowrap align-items-center mx-n2">
                                            <div className="px-2">{calendarStatusWrap()}</div>
                                            <div className="px-2">{customerStatusWrap()}</div>
                                        </div>
                                    </div>
                                    <div className="calendar-menu-seprator"></div>
                                    <div className="py-3 d-flex justify-content-center">
                                        <PoweredByCreditWrap />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="master-event-header">
                            <Row className="align-items-center flex-md-nowrap">
                                <Col xs={12} className="col-md overflow-md-hidden">
                                    <div className="d-flex align-items-center flex-nowrap flex-md-wrap flex-lg-nowrap mx-n2">
                                        <div
                                            className="px-2 pt-2 overflow-hidden mr-3 mr-xl-5 flex-grow-1"
                                            style={{ minHeight: 65 }}>
                                            {(!calendarMenuVisibility ||
                                                currentWindowWidth >= 768) &&
                                                calendarTitleAndLengthWrap}
                                        </div>
                                        {currentWindowWidth < 768 ? (
                                            <>
                                                <div className="px-2 ml-auto">
                                                    <NotificationsGroup
                                                        permission={'notification-calendar'}
                                                    />
                                                </div>
                                                <div className="px-2 order-first">
                                                    <button
                                                        aria-label="Toggle Calendar Menu"
                                                        className="common-toggler"
                                                        type="button"
                                                        onClick={() =>
                                                            setCalendarMenuVisibility(true)
                                                        }>
                                                        <span className="common-toggler-icon"></span>
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="px-2 order-first">
                                                <div
                                                    className="d-flex align-items-center flex-nowrap mx-n2"
                                                    style={{ minHeight: 65 }}>
                                                    <div className="px-2">{calendarLogoWrap}</div>
                                                    <div className="px-2">{calendarNameWrap}</div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col xs={12} className="col-md col-xxl-auto">
                                    <Row className="align-items-center justify-content-end">
                                        {currentWindowWidth >= 768 ? (
                                            <Col
                                                xs="auto"
                                                className="order-last order-xxl-last mt-2 mt-xxl-0">
                                                <div className="d-flex align-items-center mx-n2">
                                                    <div className="px-2">
                                                        <div className="d-flex flex-nowrap align-items-center mx-n2">
                                                            <div className="px-2">
                                                                {calendarStatusWrap()}
                                                            </div>
                                                            <div className="px-2">
                                                                {customerStatusWrap()}
                                                            </div>
                                                            <div className="px-2 d-none d-md-block">
                                                                <div className="border-left-separator"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-2">
                                                        <PoweredByCreditWrap />
                                                    </div>
                                                </div>
                                            </Col>
                                        ) : null}
                                        <Col xs={12} className="d-xxl-none"></Col>
                                        <Col
                                            xs={12}
                                            md="auto"
                                            className="order-first order-xxl-last">
                                            <div className="d-flex align-items-center mx-n2">
                                                {currentWindowWidth >= 768 ? (
                                                    <div className="px-2">
                                                        <NotificationsGroup
                                                            popoverPlacement={
                                                                currentWindowWidth < 1200
                                                                    ? 'bottom'
                                                                    : ''
                                                            }
                                                            permission={'notification-calendar'}
                                                        />
                                                    </div>
                                                ) : null}
                                                <div
                                                    className={`px-2 col col-md-auto ml-auto mt-2 mt-md-0  ${assignComponentPermission(
                                                        'calendar-rating'
                                                    )}`}>
                                                    <div
                                                        className={`calendar-rating-top-status ${assignComponentPermission(
                                                            'calendar-rating-calendar'
                                                        )}`}>
                                                        <div className="mx-auto pr-3">
                                                            <div className="calendar-rating">
                                                                Calendar Rating{' '}
                                                                {
                                                                    get_calendar_data?.data?.rating
                                                                        ?.stars
                                                                }
                                                                {get_calendar_data?.data?.rating
                                                                    ?.stars && '.0'}
                                                            </div>
                                                            <Tooltip title="4.0">
                                                                <StarRating
                                                                    color={'#E2C022'}
                                                                    value={
                                                                        get_calendar_data?.data
                                                                            ?.rating?.stars || 0
                                                                    }
                                                                    showTooltip
                                                                />
                                                                {/* <img
                                                                    width="100%"
                                                                    src={require('../../../assets/images/star-rating/secondary/rating-04.svg')}
                                                                    alt="4.0 rating"
                                                                /> */}
                                                            </Tooltip>
                                                        </div>
                                                        <div>
                                                            <span
                                                                role="button"
                                                                className="calendar-rating-btn"
                                                                onClick={() =>
                                                                    setIncreaseCalendarRatingModal(
                                                                        true
                                                                    )
                                                                }>
                                                                <span className="calendar-rating-btn-icon">
                                                                    <svg
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <circle
                                                                            cx="12"
                                                                            cy="12"
                                                                            r="12"
                                                                            fill="#A2792C"
                                                                        />
                                                                        <rect
                                                                            x="11"
                                                                            y="7"
                                                                            width="2"
                                                                            height="10"
                                                                            fill="white"
                                                                        />
                                                                        <rect
                                                                            x="17"
                                                                            y="11"
                                                                            width="2"
                                                                            height="10"
                                                                            transform="rotate(90 17 11)"
                                                                            fill="white"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span className="calendar-rating-btn-name">
                                                                    Increase Rating
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <FullCalendar
                            setAddProductsProductTypeModal={setAddProductsProductTypeModal}
                            setAddProductInitModalData={setAddProductInitModalData}
                            onClickAddMoreProduct={onClickAddMoreProduct}
                            setFirstCreateMainProductModal={setFirstCreateMainProductModal}
                            setSelectCalendarDaysBookingModal={setSelectCalendarDaysBookingModal}
                            setAddOnProductItineraryTimeModal={setAddOnProductItineraryTimeModal}
                        />
                    </div>
                </Col>
                <Col
                    xs={{ size: 12, order: 1 }}
                    xl={{ size: 'auto', order: 2 }}
                    className="mb-2 mb-xl-0 mt-xl-3">
                    <div className="d-flex">
                        {true ? (
                            <Button
                                color="ghost"
                                size="lg"
                                className="back-dashboard-btn px-0 mr-auto mr-xl-2"
                                onClick={() => setGoBackToDashboardModal(true)}>
                                <span className="ghost-btn-icon mr-2">
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle
                                            cx="16"
                                            cy="16"
                                            r="16"
                                            fill="black"
                                            fillOpacity="0.7"
                                        />
                                        <path
                                            d="M5.29289 15.2929C4.90237 15.6834 4.90237 16.3166 5.29289 16.7071L11.6569 23.0711C12.0474 23.4616 12.6805 23.4616 13.0711 23.0711C13.4616 22.6805 13.4616 22.0474 13.0711 21.6569L7.41421 16L13.0711 10.3431C13.4616 9.95262 13.4616 9.31945 13.0711 8.92893C12.6805 8.53841 12.0474 8.53841 11.6569 8.92893L5.29289 15.2929ZM26 15L6 15L6 17L26 17L26 15Z"
                                            fill="white"
                                        />
                                    </svg>
                                </span>
                                <span className="ghost-btn-name">Back to Dashboard</span>
                            </Button>
                        ) : null}
                        {/* <Popover
                            overlayClassName="popover-container popover-regular"
                            visible={calendarInsightsTooltipVisibility}
                            onVisibleChange={() =>
                                setCalendarInsightsTooltipVisibility(
                                    !calendarInsightsTooltipVisibility
                                )
                            }
                            content={
                                <div className="text-center d-flex flex-column align-items-center">
                                    <span
                                        className="d-inline-block text-nowrap hover-secondary"
                                        tabIndex="0"
                                        role="button"
                                        onClick={() => {
                                            setCalendarInsightsTooltipVisibility(false);
                                            setViewCalendarSales(true);
                                        }}>
                                        Calendar Sales
                                    </span>
                                    <span
                                        className="d-inline-block text-nowrap hover-secondary"
                                        tabIndex="0"
                                        role="button"
                                        onClick={() => {
                                            setCalendarInsightsTooltipVisibility(false);
                                            setViewCustomerDemand(true);
                                        }}>
                                        Customers Demand
                                    </span>
                                    <span
                                        className="d-inline-block text-nowrap hover-secondary"
                                        tabIndex="0"
                                        role="button"
                                        onClick={() => {
                                            setCalendarInsightsTooltipVisibility(false);
                                            setViewCustomerInterest(true);
                                        }}>
                                        Customers Interest
                                    </span>
                                </div>
                            }
                            trigger="click"> */}
                        <Tooltip
                            title="Coming Soon"
                            className={`${assignComponentPermission('calendar-insight-calendar')}`}>
                            <Button
                                color="white"
                                size="lg"
                                className="disabled-link__btn text-primary px-2 mr-2">
                                <span className="filled-btn-icon mr-2">
                                    <svg
                                        width="23"
                                        height="25"
                                        viewBox="0 0 23 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M22.1178 23.4976H21.4859V7.33031C21.4859 6.91547 21.1495 6.57901 20.7346 6.57901H16.8431C16.4282 6.57901 16.0918 6.91547 16.0918 7.33031V23.4976H14.2418V12.9568C14.2418 12.542 13.9055 12.2055 13.4907 12.2055H9.59895C9.1841 12.2055 8.84783 12.542 8.84783 12.9568V23.4976H6.9977V11.5768C6.9977 11.162 6.66125 10.8257 6.2464 10.8257H2.35484C1.9398 10.8257 1.60353 11.162 1.60353 11.5768V23.4976H0.972008C0.556969 23.4976 0.220703 23.8338 0.220703 24.2487C0.220703 24.6637 0.556969 25 0.972008 25H22.1178C22.5327 25 22.8691 24.6637 22.8691 24.2487C22.8691 23.8338 22.5327 23.4976 22.1178 23.4976ZM17.5942 8.08143H19.9835V23.4976H17.5942V8.08143ZM10.3503 13.7079H12.7394V23.4976H10.3503V13.7079ZM3.10595 12.3281H5.49509V23.4976H3.10595V12.3281Z"
                                            fill="#A2792C"
                                        />
                                        <path
                                            d="M5.46314 8.60672C5.48527 8.60672 5.50777 8.60577 5.53009 8.60386C5.59799 8.59776 7.21847 8.44784 9.51893 7.63283C11.6311 6.88438 14.7506 5.40466 17.6177 2.60487C17.632 2.59094 17.645 2.57626 17.658 2.56138V3.71742C17.658 4.13246 17.9942 4.46873 18.4091 4.46873C18.8239 4.46873 19.1604 4.13246 19.1604 3.71742V0.751305C19.1604 0.336266 18.8239 0 18.4091 0H15.4428C15.0279 0 14.6917 0.336266 14.6917 0.751305C14.6917 1.16615 15.0279 1.50242 15.4428 1.50242H16.5994C16.5889 1.51157 16.578 1.52016 16.5679 1.53008C15.7949 2.285 15.0018 2.93636 14.2179 3.49865C12.3092 4.86698 10.4533 5.70374 9.0665 6.19907C8.77811 6.30207 8.50136 6.39343 8.23757 6.47507C6.55052 6.99711 5.41012 7.10602 5.39715 7.10735C4.9844 7.14417 4.67941 7.50866 4.71584 7.9216C4.75037 8.31242 5.07824 8.60672 5.46314 8.60672Z"
                                            fill="#A2792C"
                                        />
                                    </svg>
                                </span>
                                <span className="filled-btn-name align-middle d-none d-md-inline">
                                    Calendar Insight
                                </span>
                            </Button>
                        </Tooltip>
                        {/* To be worked on later */}
                        {/* <Button type="dark" onClick={() => openConModal(!conModal)}>
                            <span>{' Confirm Funds '}</span>
                        </Button> */}
                        {/* <FundsConfirmation openConModal={conModal} /> */}
                        {/* </Popover> */}
                        <Feedback hideName="md" />
                        {/* <div className="dashboard-bottom-stats">
                <Popover content={expectedRevTooltip}>
                  <div className="stat-child">
                    <div className="mr-3">
                      <div className="stat-child-count primary">
                        $1.3k
                    </div>
                    </div>
                    <div>
                      <div className="stat-child-content">
                        Expected Revenue
                    </div>
                    </div>
                  </div>
                </Popover>
                <Popover content={actualSalesTooltip}>
                  <div className="stat-child">
                    <div className="mr-3">
                      <div className="stat-child-count secondary">
                        $1.3k
                    </div>
                    </div>
                    <div>
                      <div className="stat-child-content">
                        Actual Sales
                    </div>
                    </div>
                  </div>
                </Popover>
                <Popover content={customerBudgetTooltip}>
                  <div className="stat-child">
                    <div className="mr-3">
                      <div className="stat-child-count tertiary">
                        $2.5k
                    </div>
                    </div>
                    <div>
                      <div className="stat-child-content">
                        Av. Customer Budget
                    </div>
                    </div>
                  </div>
                </Popover>
              </div> */}
                    </div>
                </Col>
                <Col
                    xs={{ size: 12, order: 3 }}
                    xl={{ size: 'auto', order: 3 }}
                    className={`mt-3 ml-xl-auto ${assignComponentPermission('product')}`}>
                    <div className="d-flex mx-n1 mx-md-n2">
                        <Button
                            onClick={() => {
                                mainProduct || mainPackage
                                    ? onClickAddMoreProduct()
                                    : setFirstCreateMainProductModal(true);
                            }}
                            className={`flex-shrink-0 btn-icon-only p-0 rounded-circle has-tooltip mx-1 mx-md-2 ${assignComponentPermission(
                                'product-update'
                            )}`}
                            color="primary"
                            size="lg">
                            <svg
                                width="16"
                                height="21"
                                viewBox="0 0 16 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect x="11" y="13" width="2" height="8" fill="white" />
                                <rect
                                    x="16"
                                    y="16"
                                    width="2"
                                    height="8"
                                    transform="rotate(90 16 16)"
                                    fill="white"
                                />
                                <path
                                    d="M3.71297 10.712V17H0.328969V0.0559993H6.49697C8.49697 0.0559993 10.001 0.535999 11.009 1.496C12.033 2.456 12.545 3.76 12.545 5.408C12.545 7.024 12.017 8.312 10.961 9.272C9.92097 10.232 8.43297 10.712 6.49697 10.712H3.71297ZM6.13697 8C8.12097 8 9.11297 7.136 9.11297 5.408C9.11297 4.56 8.88097 3.912 8.41697 3.464C7.95297 3.016 7.19297 2.792 6.13697 2.792H3.71297V8H6.13697Z"
                                    fill="white"
                                />
                            </svg>

                            {/* Tooltip */}
                            {!firstCreateMainProductModal && !addProductsInitModal ? (
                                <div className="main-tooltip">
                                    <div className="main-tooltip-body">
                                        <span
                                            className="hover-secondary"
                                            role="button"
                                            tabIndex="0">
                                            Add More Products
                                        </span>
                                    </div>
                                </div>
                            ) : null}
                        </Button>
                        {/* Create main product */}
                        {!(mainProduct || mainPackage) ? (
                            <Button
                                onClick={() => createMainProduct()}
                                className={`flex-shrink-0 btn-icon-only p-0 rounded-circle has-tooltip mx-1 mx-md-2 ${assignComponentPermission(
                                    'product-update'
                                )}`}
                                color="primary"
                                size="lg">
                                <svg
                                    width="36"
                                    height="21"
                                    viewBox="0 0 36 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.9289 0.248V17H15.5689V5.312L11.2729 17H8.34491L4.04891 5.36V17H0.664906V0.248H4.76891L9.85691 12.848L14.8489 0.248H18.9289ZM25.377 10.712V17H21.993V0.0559993H28.161C30.161 0.0559993 31.665 0.535999 32.673 1.496C33.697 2.456 34.209 3.76 34.209 5.408C34.209 7.024 33.681 8.312 32.625 9.272C31.585 10.232 30.097 10.712 28.161 10.712H25.377ZM27.801 8C29.785 8 30.777 7.136 30.777 5.408C30.777 4.56 30.545 3.912 30.081 3.464C29.617 3.016 28.857 2.792 27.801 2.792H25.377V8H27.801Z"
                                        fill="white"
                                    />
                                    <rect x="31" y="13" width="2" height="8" fill="white" />
                                    <rect
                                        x="36"
                                        y="16"
                                        width="2"
                                        height="8"
                                        transform="rotate(90 36 16)"
                                        fill="white"
                                    />
                                </svg>
                                {/* Tooltip */}
                                {!createMainProductModal ? (
                                    <div className="main-tooltip">
                                        <div className="main-tooltip-body">
                                            <span
                                                className="hover-secondary"
                                                role="button"
                                                tabIndex="0">
                                                Create Your Main Product
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                            </Button>
                        ) : (
                            // Edit/Change Main Product button
                            <Button
                                onClick={() => {
                                    setEditMainProductInitModal(true);
                                    //setCreateMainProductModal(true);
                                }}
                                className={`flex-shrink-0 btn-icon-only p-0 rounded-circle has-tooltip mx-1 mx-md-2 ${assignComponentPermission(
                                    'product-update'
                                )}`}
                                color="primary"
                                size="lg">
                                <svg
                                    width="36"
                                    height="23"
                                    viewBox="0 0 36 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.8263 0.248V17H15.4663V6.104L10.9783 17H8.43425L3.92225 6.104V17H0.56225V0.248H4.37825L9.70625 12.704L15.0343 0.248H18.8263ZM34.3642 5.432C34.3642 6.328 34.1482 7.168 33.7162 7.952C33.3002 8.736 32.6362 9.368 31.7242 9.848C30.8282 10.328 29.6922 10.568 28.3162 10.568H25.5082V17H22.1482V0.248H28.3162C29.6122 0.248 30.7162 0.472 31.6282 0.92C32.5402 1.368 33.2202 1.984 33.6682 2.768C34.1322 3.552 34.3642 4.44 34.3642 5.432ZM28.1722 7.856C29.1002 7.856 29.7882 7.648 30.2362 7.232C30.6842 6.8 30.9082 6.2 30.9082 5.432C30.9082 3.8 29.9962 2.984 28.1722 2.984H25.5082V7.856H28.1722Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M26.6056 19.6488L26.0148 22.1986C25.9944 22.2918 25.9951 22.3884 26.0168 22.4813C26.0386 22.5742 26.0808 22.6611 26.1404 22.7356C26.2 22.8101 26.2755 22.8703 26.3613 22.9119C26.4472 22.9535 26.5413 22.9754 26.6367 22.9759C26.6811 22.9804 26.7259 22.9804 26.7704 22.9759L29.3357 22.3851L34.261 17.4784L31.5123 14.7359L26.6056 19.6488Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M35.8094 15.0219L33.9749 13.1874C33.8542 13.0674 33.691 13 33.5209 13C33.3507 13 33.1875 13.0674 33.0669 13.1874L32.047 14.2073L34.7926 16.9529L35.8125 15.933C35.8722 15.873 35.9195 15.8019 35.9516 15.7236C35.9838 15.6453 36.0002 15.5615 35.9999 15.4768C35.9996 15.3922 35.9826 15.3085 35.95 15.2304C35.9173 15.1524 35.8695 15.0815 35.8094 15.0219Z"
                                        fill="white"
                                    />
                                </svg>
                                {/* Tooltip */}
                                {/* {!editChangeMainProductModal ? ( */}
                                <div className="main-tooltip">
                                    <div className="main-tooltip-body">
                                        <span
                                            className="hover-secondary"
                                            role="button"
                                            tabIndex="0">
                                            Edit Your Main Product
                                        </span>
                                        {/* <br />
                                                <span
                                                    className="hover-secondary"
                                                    role="button"
                                                    tabIndex="0">
                                                    Change Your Main Product
                                            </span> */}
                                    </div>
                                </div>
                                {/* ) : null} */}
                            </Button>
                        )}

                        <Button
                            className="flex-shrink-0 btn-icon-only p-0 rounded-circle has-tooltip ml-1 mr-2 mx-md-2"
                            color="primary"
                            size="lg">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M21.257 10.962C21.731 11.582 21.731 12.419 21.257 13.038C19.764 14.987 16.182 19 12 19C7.81801 19 4.23601 14.987 2.74301 13.038C2.51239 12.7411 2.38721 12.3759 2.38721 12C2.38721 11.6241 2.51239 11.2589 2.74301 10.962C4.23601 9.013 7.81801 5 12 5C16.182 5 19.764 9.013 21.257 10.962V10.962Z"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            {/* Tooltip */}
                            <div className="main-tooltip">
                                <div className="main-tooltip-body">
                                    <span className="hover-secondary" role="button" tabIndex="0">
                                        Calendar Preview
                                    </span>
                                </div>
                            </div>
                        </Button>
                        <Button
                            className={`mr-1 mr-md-2 ml-auto ml-xl-2 acc-btn ${assignComponentPermission(
                                'access-customer-calendar'
                            )}`}
                            onClick={() => props.toggleAccessCalendarModal(true)}
                            size="lg"
                            color="dark">
                            Access Customer Calendar
                        </Button>
                    </div>
                </Col>
            </Row>
            {/* <div className="mt-3 pull-right">
                <Button
                    size="lg"
                    onClick={() => {
                        handleCreateMainProduct();
                    }}>
                    Create Main Product
                </Button>
            </div> */}
            <div className="calendar mt-3" hidden>
                <CreateMainProduct
                    showModal={openCreateMainProduct}
                    onClose={() => {
                        handleCreateMainProduct();
                    }}
                    onOpenActivity={() => {
                        handleActivityModal();
                        setOpenCreateMainProduct(false);
                    }}
                    onOpenAccomodation={() => {
                        handleAccomodationMinipage();
                        setOpenCreateMainProduct(false);
                    }}
                />
                <AccomodationMinipage
                    showModal={openAccomodationMinipage}
                    onClose={() => {
                        handleAccomodationMinipage();
                    }}
                />

                <AntModal
                    visible={openModal}
                    closable={true}
                    footer={false}
                    onCancel={() => {
                        setopenModal(false);
                    }}
                    width={'80%'}>
                    <div className="calendar-modal-content" style={{ height: '80vh' }}>
                        <TextDropDown
                            name="Select"
                            className="input-bar-dropdown"
                            caret={Caret}
                            down={Down}
                            class={'image-icon-down'}
                            icon={'image-icon'}>
                            {heirachy.map((item) => {
                                return (
                                    <TextDropDown
                                        caret={Caret}
                                        down={Down}
                                        isCheckBox={false}
                                        isSidebar={true}
                                        name={item.name}
                                        key={item.name}
                                        className="input-no-curve"
                                        class={'image-icon-down'}
                                        icon={'image-icon'}>
                                        {item.child &&
                                            item.child.map((child) => {
                                                return (
                                                    <TextDropDown
                                                        caret={Caret}
                                                        down={Down}
                                                        isCheckBox={false}
                                                        isSidebar={true}
                                                        name={child.name}
                                                        key={child.name}
                                                        className="input-no-curve"
                                                        class={'image-icon-down'}
                                                        icon={'image-icon'}>
                                                        {child.grandChild &&
                                                            child.grandChild.map((cat) => {
                                                                return (
                                                                    <TextDropDown
                                                                        widthy="100%"
                                                                        caret={Caret}
                                                                        down={Down}
                                                                        isCheckBox={false}
                                                                        isSidebar={false}
                                                                        name={cat}
                                                                        key={cat}
                                                                        className="input-no-curve"
                                                                        class={'image-icon-down'}
                                                                        icon={'image-icon'}
                                                                    />
                                                                );
                                                            })}
                                                    </TextDropDown>
                                                );
                                            })}
                                    </TextDropDown>
                                );
                            })}
                        </TextDropDown>
                    </div>
                </AntModal>
            </div>

            <Drawer
                width={609}
                placement="right"
                className="basic-drawer"
                closeIcon={
                    <span role="img" aria-label="close" className="anticon anticon-close">
                        <svg
                            width="24"
                            height="27"
                            viewBox="0 0 24 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.1988 13.4683L23.5439 3.5314C24.1521 2.88505 24.1521 1.83998 23.5439 1.19363C22.936 0.547274 21.9532 0.547274 21.3453 1.19363L11.9999 11.1305L2.65474 1.19363C2.04658 0.547274 1.064 0.547274 0.456124 1.19363C-0.152041 1.83998 -0.152041 2.88505 0.456124 3.5314L9.80125 13.4683L0.456124 23.4052C-0.152041 24.0515 -0.152041 25.0966 0.456124 25.7429C0.759068 26.0654 1.15739 26.2273 1.55543 26.2273C1.95347 26.2273 2.35152 26.0654 2.65474 25.7429L11.9999 15.8061L21.3453 25.7429C21.6485 26.0654 22.0466 26.2273 22.4446 26.2273C22.8426 26.2273 23.2407 26.0654 23.5439 25.7429C24.1521 25.0966 24.1521 24.0515 23.5439 23.4052L14.1988 13.4683Z"
                                fill="black"
                            />
                        </svg>
                    </span>
                }
                onClose={() => {
                    setShowEventDetailsModal(false);
                }}
                visible={showEventDetailsModal}>
                <div className="d-flex flex-column h-100">
                    <div>
                        <h4 className="modal-title">
                            Burj al khalifa
                            <span role="button" tabIndex="0">
                                <svg
                                    width="33"
                                    height="32"
                                    viewBox="0 0 33 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16.1488" cy="16" r="16" fill="white" />
                                    <path
                                        d="M7.98284 19.0815L6.85691 23.9408C6.81807 24.1184 6.8194 24.3025 6.86081 24.4795C6.90221 24.6566 6.98265 24.8222 7.09624 24.9641C7.20983 25.1061 7.3537 25.2209 7.51734 25.3002C7.68099 25.3795 7.86027 25.4212 8.0421 25.4223C8.12682 25.4308 8.21219 25.4308 8.29691 25.4223L13.1858 24.2963L22.5725 14.9452L17.334 9.71857L7.98284 19.0815Z"
                                        fill="#A2792C"
                                    />
                                    <path
                                        d="M25.5236 10.2637L22.0273 6.76737C21.7975 6.53866 21.4864 6.41028 21.1622 6.41028C20.8379 6.41028 20.5268 6.53866 20.297 6.76737L18.3533 8.71107L23.5859 13.9437L25.5296 12C25.6433 11.8856 25.7334 11.75 25.7947 11.6008C25.8559 11.4517 25.8872 11.2919 25.8867 11.1306C25.8861 10.9693 25.8538 10.8097 25.7915 10.661C25.7292 10.5122 25.6382 10.3772 25.5236 10.2637Z"
                                        fill="#A2792C"
                                    />
                                </svg>
                            </span>
                        </h4>

                        <div>
                            <div className="d-flex mx-n2">
                                <div className="px-2">
                                    <span className="text-uppercase fw-600 fs-14 mr-2">
                                        Available:
                                    </span>
                                    <span className="fs-14 text-nowrap">
                                        <span
                                            className={`calendar_activity-status __${'No'
                                                .toLowerCase()
                                                .replace(/ /g, '_')}`}></span>
                                        <span className="fs-14 text-gray">No</span>
                                    </span>
                                </div>
                                <div className="px-2">
                                    <span className="text-uppercase fw-600 fs-14 mr-2">
                                        Product status:
                                    </span>
                                    <span className="fs-14 text-nowrap">
                                        <span
                                            className={`calendar_activity-status __${'In Use'
                                                .toLowerCase()
                                                .replace(/ /g, '_')}`}></span>
                                        <span className="fs-14 text-gray">In Use</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex-grow-1 overflow-y-auto overflow-x-hidden scrollbar-fixed pr-2">
                        <div className="ff-special">
                            <p>
                                Burj Khalifa is the tallest tower in the world and it's one of the
                                top attractions to visit in Dubai. Visit our website and book your
                                Burj Khalifa ticket.
                            </p>

                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc non
                                eros ac sapien suscipit sollicitudin a finibus mauris. Donec nulla
                                ligula, auctor a varius id, viverra sed ante. Donec sodales cursus
                                sem, in vulputate dolor dictum ac. Fusceunt eros quis cursus
                                commodo. Nullam ligula tortor, consectetur sit amet gravida quis,
                                hendrerit sit amet libero. Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Integer imperdiet nisl quis rutrum malesuada. Ut
                                posuere tempus ante, quis ultricies nisl posuere at
                            </p>
                        </div>
                    </div>

                    <div className="pt-3">
                        <div className="d-flex justify-content-between">
                            <Button color="primary">Go Back</Button>
                            <Feedback />
                        </div>
                    </div>
                </div>
            </Drawer>

            {/* The very first add products modal */}
            {addProductsInitModal && (
                <AddProductsInitModal
                    isModalOpen={addProductsInitModal}
                    setModalOpenState={setAddProductsInitModal}
                    setAddProductsProductTypeModal={setAddProductsProductTypeModal}
                    productsAvailabilityType={productsAvailabilityType}
                    setAddProductInitModalData={setAddProductInitModalData}
                    addProductInitModalData={addProductInitModalData}
                />
            )}

            {/* Cotinue adding product of product type modal */}
            {addProductsProductTypeModal && (
                <AddProductsProductTypeModal
                    selectedDay={addProductInitModalData}
                    isModalOpen={addProductsProductTypeModal}
                    setModalOpenState={setAddProductsProductTypeModal}
                    handleCreate={() => {
                        setSelectCalendarDaysBookingModal(true);
                    }}
                    setPreviousModalOpenState={setAddProductsInitModal}
                    setAddProductInitModalData={setAddProductInitModalData}
                    setAddOnProductItineraryTimeModal={setAddOnProductItineraryTimeModal}
                    setAddProductItineraryModal={setAddProductItineraryModal}
                />
            )}

            {addOnProductItineraryTimeModal && (
                <AddItineraryTimeModal
                    isModalOpen={addOnProductItineraryTimeModal}
                    setModalOpenState={setAddOnProductItineraryTimeModal}
                    updateAndContinue={() => {
                        setAddOnProductItineraryTimeModal(false);
                        setSelectCalendarDaysBookingModal(true);
                    }}
                    setPreviousModalOpenState={setAddProductsProductTypeModal}
                    setEditMainProductInitModal={setEditMainProductInitModal}
                />
            )}

            {/* Select the customers calendar days Modal Popup */}
            {selectCalendarDaysBookingModal && (
                <CalendarAllDaysModal
                    isOpen={true}
                    updateAndContinue={() => {
                        setSelectCalendarDaysBookingModal(false);
                        setAddProductItineraryModal(true);
                    }}
                    closeCalendarModal={() => {
                        setSelectCalendarDaysBookingModal(false);
                    }}
                    setAddOnProductItineraryTimeModal={setAddOnProductItineraryTimeModal}
                    setAddProductsProductTypeModal={setAddProductsProductTypeModal}
                    setEditMainProductInitModal={setEditMainProductInitModal}
                />
            )}

            {/* Add an Itinerary */}
            {addProductItineraryModal && (
                <AddAnItineraryModal
                    isOpen={addProductItineraryModal}
                    onClose={() => setAddProductItineraryModal(false)}
                    setAddProductInitModalData={setAddProductInitModalData}
                    hideImport
                    setOpenActivityModal={setOpenActivityModal}
                />
            )}

            {/* Add event Modal Popup */}
            {/* Currently not in use */}
            <Modal centered scrollable size="lg" isOpen={eventModal}>
                <Form
                    className="d-flex flex-column h-100 form-label-fw-500"
                    layout="vertical"
                    requiredMark={false}>
                    <ModalHeader>Add Event</ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="ant-form-item-label">
                                <label className="ant-form-item-required">
                                    Select Event Destination?
                                </label>
                            </div>
                            <div className="d-flex flex-column overflow-x-auto overflow-y-hidden scrollbar-fixed mb-3">
                                <Image.PreviewGroup>
                                    <div className="d-flex flex-nowrap image-preview-group">
                                        <Image
                                            src={require('../../../assets/images/dubai-creeks.jpg')}
                                        />
                                        <Image
                                            src={require('../../../assets/images/burj-al-khalifa.jpg')}
                                        />
                                        <Image
                                            src={require('../../../assets/images/burj-al-khalifa.jpg')}
                                        />
                                        <Image
                                            src={require('../../../assets/images/burj-al-khalifa.jpg')}
                                        />
                                    </div>
                                </Image.PreviewGroup>
                                <Form.Item
                                    rules={[{ required: true, message: 'Please select one' }]}
                                    name="event_destination">
                                    <Radio.Group
                                        className="d-flex flex-nowrap select-image-name-group mt-3"
                                        optionType="button"
                                        buttonStyle="solid"
                                        onChange={(e) => setEventDestination(e.target.value)}
                                        value={eventDestination}
                                        options={selectDestinationOptions}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        {eventDestination ? (
                            <>
                                <Checkbox
                                    className="float-right"
                                    onChange={(e) => setPrefferedDate(e.target.checked)}>
                                    Preffered Date
                                </Checkbox>
                                {prefferedDate ? (
                                    <Form.Item
                                        label="Start &amp; End Time?"
                                        rules={[
                                            { required: true, message: 'Please select date & time' }
                                        ]}
                                        name="select_date_time">
                                        <RangePicker
                                            size="large"
                                            showTime={{ format: 'HH:mm' }}
                                            format="YYYY-MM-DD HH:mm"
                                            placeholder={['Start Date', 'End Date']}
                                        />
                                    </Form.Item>
                                ) : (
                                    <Form.Item
                                        label="Start &amp; End Time?"
                                        rules={[{ required: true, message: 'Please select time' }]}
                                        name="select_date_time">
                                        <TimePicker.RangePicker
                                            size="large"
                                            placeholder={['Start Time', 'End Time']}
                                        />
                                    </Form.Item>
                                )}
                            </>
                        ) : null}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" className="btn-mw" type="submit">
                            Add
                        </Button>
                        <Button
                            color="secondary"
                            className="btn-mw"
                            type="button"
                            onClick={() => setEventModal(false)}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            {/* Calendar Rating Modal Popup */}
            <CalendarRatingModal
                isModalOpen={increaseCalendarRatingModal}
                setModalOpenState={setIncreaseCalendarRatingModal}
                increaseRating={{ setAvailableHoursModal, setProductQualityModal }}
            />

            {/* Available Hours Modal Popup */}
            <AvailableHoursModal
                isModalOpen={availableHoursModal}
                setModalOpenState={setAvailableHoursModal}
                setAddProductsInitModal={setAddProductsInitModal}
                goBackAction={() => {
                    setAvailableHoursModal(false);
                    setIncreaseCalendarRatingModal(true);
                }}
            />

            {/* Product Quality Modal Popup */}
            <ProductQualityModal
                isModalOpen={productQualityModal}
                setModalOpenState={setProductQualityModal}
                goBackAction={() => {
                    setProductQualityModal(false);
                    setIncreaseCalendarRatingModal(true);
                }}
            />

            {/* Go back to dashboard warning modal */}
            <Modal
                size="lg"
                centered
                isOpen={goBackToDashboardModal}
                toggle={() => setGoBackToDashboardModal(!goBackToDashboardModal)}>
                <ModalBody className="text-center">
                    <img src={warningIcon} alt="" />
                    <div className="modal-body-title-lg mt-2">Are you sure?</div>
                    <div className="mt-4 mx-n2">
                        <Button
                            color="primary"
                            className="btn-mw mx-2 btn-block btn-md-inline-block"
                            onClick={backToDashboard}>
                            Go Back
                        </Button>
                        <Button
                            color="dark"
                            className="btn-mw mx-2 btn-block btn-md-inline-block"
                            onClick={() => setGoBackToDashboardModal(false)}>
                            Continue Editing
                        </Button>
                    </div>
                </ModalBody>
            </Modal>

            {/* First Create Main Product Modal */}
            <Modal
                size="lg"
                centered
                isOpen={firstCreateMainProductModal}
                toggle={() => setFirstCreateMainProductModal(!firstCreateMainProductModal)}>
                <ModalHeader className="text-center">
                    First create a Main Product to be able to add More Products
                </ModalHeader>
                <ModalFooter className="flex-column flex-md-row text-center justify-content-center">
                    <Button
                        color="primary"
                        className="btn-mw btn-block btn-md-inline-block"
                        onClick={() => {
                            setFirstCreateMainProductModal(false);
                            //setCreateMainProductModal(true);
                            setCreateMainProductInitModal(true);
                            restingCalendarReducers();
                            resettingProductReducers();
                        }}>
                        Create Main Product
                    </Button>
                    <Button
                        color="dark"
                        className="btn-mw btn-block btn-md-inline-block"
                        onClick={() => setFirstCreateMainProductModal(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Duplicate Calendar Modal Popup */}
            {/* TODO: add it here */}
            <DuplicateCalendarModal
                form={form}
                duplicateCalendarModalPopup={duplicateCalendarModalPopup}
                setDuplicateCalendarModalPopup={setDuplicateCalendarModalPopup}
                setLoading={setLoadingCalendarById}
            />
            {/* Create Main Product Modal Popup */}

            {createMainProductModal && (
                <ExistingMainProductModal
                    createMainProductModal={createMainProductModal}
                    setCreateMainProductModal={setCreateMainProductModal}
                    setCreateMainProductPackageModal={setCreateMainProductPackageModal}
                    setOpenActivityModal={setOpenActivityModal}
                    setAddProductItineraryModal={setAddProductItineraryModal}
                    setAddOnProductItineraryTimeModal={setAddOnProductItineraryTimeModal}
                    setSelectCalendarDaysBookingModal={setSelectCalendarDaysBookingModal}
                />
            )}
            {/* Create Main Product Package Modal */}

            <AddNewMainProductModal
                createMainProductPackageModal={createMainProductPackageModal}
                setCreateMainProductPackageModal={setCreateMainProductPackageModal}
                setCreateMainProductModal={setCreateMainProductModal}
                setCreateYourPackageDrawer={setCreateYourPackageDrawer}
                setAddOnProductItineraryTimeModal={setAddOnProductItineraryTimeModal}
                setSelectCalendarDaysBookingModal={setSelectCalendarDaysBookingModal}
                setCreateMainProductInitModal={setCreateMainProductInitModal}
                changeWithNewProduct={changeWithNewProduct}
                setChangeWithNewProduct={setChangeWithNewProduct}
                setEditMainProductInitModal={setEditMainProductInitModal}
            />
            {/* Create your package Drawer */}
            <CreatePackageDrawer
                createYourPackageDrawer={createYourPackageDrawer}
                setCreateYourPackageDrawer={setCreateYourPackageDrawer}
                setOpenActivityModal={setOpenActivityModal}
            />

            <ItineraryForm
                showModal={openActivityModal}
                onClose={() => {
                    handleActivityModal();
                }}
                onOpenAnotherDrawer={onOpenAnotherDrawer}
                onEditPackageCategory={() => {
                    setCreateMainProductPackageModal(true);
                }}
                toggleAddItineraryModal={() => {
                    setAddProductItineraryModal(true);
                }}
                setAddProductsInitModal={setAddProductsInitModal}
                toggleOnEditPackageProduct={(type) => {
                    if (type === 'all_day') {
                        setSelectCalendarDaysBookingModal(true);
                    } else {
                        setAddOnProductItineraryTimeModal(true);
                    }
                }}
            />

            {/* Calendar Insights Modal Popups */}
            {/* Calendar Sales Modal */}
            <CalendarSalesModal
                isModalOpen={viewCalendarSales}
                setModalOpenState={setViewCalendarSales}
            />

            {/* Customer Demand Modal */}
            <CustomerDemandModal
                isModalOpen={viewCustomerDemand}
                setModalOpenState={setViewCustomerDemand}
            />

            {/* Customer Interest Modal */}
            <CustomerInterestModal
                isModalOpen={viewCustomerInterest}
                setModalOpenState={setViewCustomerInterest}
            />

            {/* Add an Itinerary */}
            <ModalComponent
                isOpen={addAnItineraryModal}
                onClose={() => setAddAnItineraryModal(false)}
            />

            {/* Create Main Product Init Modal */}
            <CreateMainProductInitModal
                isOpen={createMainProductInitModal}
                toggleModal={() => {
                    setCreateMainProductInitModal(!createMainProductInitModal);
                }}
                setCreateMainProductPackageModal={setCreateMainProductPackageModal}
                setCreateMainProductModal={setCreateMainProductModal}
            />

            {/* Changing Main Product Init Modal */}
            <EditMainProductInitModal
                isOpen={editMainProductInitModal}
                toggleModal={() => {
                    setEditMainProductInitModal(!editMainProductInitModal);
                }}
                setCreateMainProductModal={setCreateMainProductModal}
                setAddOnProductItineraryTimeModal={setAddOnProductItineraryTimeModal}
                setSelectCalendarDaysBookingModal={setSelectCalendarDaysBookingModal}
                setOpenActivityModal={setOpenActivityModal}
                setCreateMainProductPackageModal={setCreateMainProductPackageModal}
                setChangeWithNewProduct={setChangeWithNewProduct}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    business_data: state.calendar.business_data,
    update_business_data_loading: state.calendar.update_business_data_loading,
    update_calendar_product: state.calendar.update_calendar_product,
    upate_calendar_title: state.calendar.upate_calendar_title,
    get_calendar_data: state.calendar.get_calendar_data,
    update_calendar_length: state.calendar.update_calendar_length,
    selected_existing_product: state.product.selected_existing_product,
    showUpdateProductFields: state?.product?.showUpdateProductFields,
    current_product: state.product.currentProduct,
    acc_loading: state.accessCalendarReducer.acc_loading
});

const mapDispatch = (dispatch) => ({
    getBusinessNameLogo: () => dispatch(getBusinessNameLogo()),
    updateBusinessNameLogo: (data) => dispatch(updateBusinessNameLogo(data)),
    updateBusinessNameLogoLoading: (data) => dispatch(updateBusinessNameLogoLoading_(data)),
    toggleAccessCalendarModal: (value) => dispatch(toggleAccessCalendarModal(value)),
    updateCalendarTitle: (data, id) => dispatch(updateCalendarTitle(data, id)),
    getCalendarsById: (id) => dispatch(getCalendarsById(id)),
    updateCalendarLength: (data, id) => dispatch(updateCalendarLength(data, id)),
    updateCalendarLgSuccess: (data) => dispatch(updateCalendarLgSuccess(data)),
    resetCalenderReducer: (data) => dispatch(resetCalenderReducer(data)),
    resetProductReducer: (data) => dispatch(resetProductReducer(data)),
    setUpdateProductFields_: (data) => dispatch(setUpdateProductFields_(data))
});

CalendarComponent.propTypes = {
    business_data: PropTypes.any,
    update_business_data_loading: PropTypes?.bool,
    getBusinessNameLogo: PropTypes.func,
    updateBusinessNameLogo: PropTypes.func,
    updateBusinessNameLogoLoading: PropTypes.func,
    current_product: PropTypes.object,
    update_calendar_product: PropTypes.object,
    toggleAccessCalendarModal: PropTypes.func,
    get_calendar_data: PropTypes.object,
    getCalendarsById: PropTypes.func,
    update_calendar_length: PropTypes.object,
    updateCalendarLgSuccess: PropTypes.func,
    match: PropTypes.any,
    updateCalendarTitle: PropTypes.func,
    updateCalendarLength: PropTypes.func,
    selected_existing_product: PropTypes.bool,
    history: PropTypes.shape({
        push: PropTypes.func
    }),
    resetCalenderReducer: PropTypes.func,
    resetProductReducer: PropTypes.func,
    showUpdateProductFields: PropTypes.any,
    setUpdateProductFields_: PropTypes.any,
    acc_loading: PropTypes.bool
};

export default withRouter(connect(mapStateToProps, mapDispatch)(CalendarComponent));
